import { ProjectIdType } from "../types/public-types";

export const ROUTES = {
  HOME: "/",
  LOG_IN: "/log-in",
  SIGN_UP: "/sign-up",
  FORGET_PASSWORD: "/forget-password",
  SETUP_NEW_PASSWORD: "/setup-new-password",
  PROJECTS: {
    VIEW: (projectId: ProjectIdType) => `/project/${projectId}`,
  },
  STORYBOARD: {
    VIEW: (projectId: ProjectIdType) => `/project/${projectId}/storyboard`,
    SEQUENCE: (projectId: ProjectIdType, sequenceId: string) =>
      `/project/${projectId}/storyboard/sequence/${sequenceId}`,
  },
  EXPORT_PREVIEW: {
    VIEW: (projectId: ProjectIdType) => `/project/${projectId}/export-preview`,
  },
  CREDITS_PAGE: "/credit-page",
  TERMS_AND_CONDITIONS: "/termsandcondition",
} as const;
