import {
  Box,
  Button,
  Grid,
  Modal,
  Slide,
  Tooltip,
  Typography,
} from "@mui/material";
import { CaretLeft, X } from "@phosphor-icons/react";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ScriptBreakdownContext } from "../../../Context/ContextDataProvider";
import { ROUTES } from "../../../Routes";
import { generateStoryBoard } from "../../../Services/StoryboardService/StoryBoardService";
import {
  getSequenceListApi,
  getShotListApi,
} from "../../../Services/breakdownServices/breakdownServices";
import { getProjectDetails } from "../../../Services/projectPageServices/projectPageServices";
import {
  ProjectIdType,
  SequenceListType,
  SequenceType,
  ShotListType,
  SingleShotType,
} from "../../../types/public-types";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import InsufficientCreditModal from "../../ReusableComponents/InsufficientCreditModal/InsufficientCreditModal";
import Loader from "../../ReusableComponents/Loader/Loader";
import ResponsivePhosphorIcon from "../../ReusableComponents/SvgAndIcons/ResponsivePhosphorIcon";
import { StoryBoardIcon } from "../../ReusableComponents/SvgAndIcons/StoryBoardIcon";
import {
  NextIcon,
  PreviousIcon,
  SelectAllIcon,
} from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import { StoryBoardAnimatedData } from "../../Utilities/Lotties/StoryBoardAnimatedData";
import LoadingAnimationWithProgress from "../../Utilities/Modals/LoadingAnimationWithProgress";
import SelectStoryBoardShotVersionModal from "../SequenceStoryBoardModals/SelectStoryBoardShotVersionModal";
import SequenceThumbnailGenerateModal from "../SequenceStoryBoardModals/SequenceThumbnailGenerateModal";
import SingleShotThumbnailGenerateModal from "../SequenceStoryBoardModals/SingleShotThumbnailGenerateModal";
import SingleShotOfStoryBoard from "../SingleShotOfStoryBoard/SingleShotOfStoryBoard";
import StoryBoardProgress from "../StoryBoardProgress/StoryBoardProgress";
import useStyles from "./SequenceStoryBoardOverview.styles";

type Props = {
  projectId: ProjectIdType;
  sequenceId: SequenceType["id"];
};

const SequenceStoryBoardOverview = ({ projectId, sequenceId }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { getCredits } = useContext(ScriptBreakdownContext);
  const [sequenceList, setSequenceList] = useState<SequenceListType>([]);
  const [isSequenceThumbnailOpen, setSequenceThumbnailOpen] =
    useState<boolean>(false);

  const [isSingleShotGenerationModalOpen, setIsSingleShotGenerationModalOpen] =
    useState<boolean>(false);
  const [selectedSequence, setSelectedSequence] = useState<SequenceType | null>(
    null
  );
  const [isShotsListLoading, setIsShotsListLoading] = useState<boolean>(true);

  const [newJobRefreshCounter, setNewJobRefreshCounter] = useState<number>(0);

  const handleSequenceThumbnailModal = () => {
    setSequenceThumbnailOpen(!isSequenceThumbnailOpen);
  };

  // States for storing api data
  const [shotList, setShotList] = useState<ShotListType>([]);

  //Loaders state
  const [isThumbnailsGenerating, setIsThumbnailsGenerating] =
    useState<boolean>(false);

  // style type state
  const [selectedGenerationStyle, setsSelectedGenerationStyle] = useState(1);

  // single selection state
  const [singleSelectedShot, setSingleSelectedShot] = useState<any>({});
  const [generationType, setGenerationType] = useState<string>("all");
  const [shotDetailsToSelectVersion, setShotDetailsToSelectVersion] = useState({
    isOpen: false,
    shotDetail: null,
  });

  const [refreshCounter, setRefreshCounter] = useState<number>(0);
  const [insufficientCreditModal, setInsufficientCreditModal] =
    useState<boolean>(false);
  const [isBoardGenerationInProg, setIsBoardGenerationInProg] = useState(false);

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const handleOpenShotVersion = (shot: any) => {
    setShotDetailsToSelectVersion({
      isOpen: true,
      shotDetail: shot,
    });
  };
  const [selectedShots, setSelectedShots] = useState<string[]>([]);

  const handleSelectUnSelectShot = (id: string) => {
    if (selectedShots.length) {
      const isSelected = selectedShots.some((sinId: string) => sinId === id);
      if (isSelected) {
        setSelectedShots(selectedShots.filter((sinId: string) => sinId !== id));
      } else {
        setSelectedShots([...selectedShots, id]);
      }
    } else {
      setSelectedShots([id]);
    }
  };

  const handleSelectAndUnselectAllShots = (type: string) => {
    if (type === "selectAll") {
      const ids = shotList?.map(
        (singleShot: SingleShotType) => singleShot?.shotId
      );
      setSelectedShots(ids);
    } else if (type === "unselectAll") {
      setSelectedShots([]);
    }
  };

  const handleGetShotList = async (id: any) => {
    try {
      const res = await getShotListApi(id);
      setShotList(res.data.shots);
      setIsShotsListLoading(false);
    } catch (error) {
      setIsShotsListLoading(false);
      toast.error("Error getting shots");
    }
  };

  const handleGetBreakdownVersionList = async (
    breakdownId: SequenceType["selectedBreakdown"]
  ) => {
    // setIsShotsListLoading(true);
    try {
      handleGetShotList(breakdownId);
    } catch (error) {
      toast.error("Error getting breakdown list");
    }
  };

  useEffect(() => {
    if (selectedSequence?.id && selectedSequence.selectedBreakdown) {
      handleGetBreakdownVersionList(selectedSequence.selectedBreakdown);
    }
    return () => {};
  }, [selectedSequence, refreshCounter]);

  const handleGetSequences = async (id: string) => {
    // FIX-ME, GET only single sequence object!
    try {
      const res = await getSequenceListApi(projectId, id);
      if (res?.data?.sequences?.length) {
        setSequenceList(res.data.sequences);
        setSelectedSequence(
          res.data.sequences.filter(
            (eachSeqence) => eachSeqence.id === sequenceId
          )[0]
        );
      }
    } catch (error) {
      toast.error("Error getting sequences");
    }
  };

  const handleGetProjectDetails = async () => {
    setIsShotsListLoading(true);
    try {
      const res = await getProjectDetails(projectId);
      if (res.data.linkedFile) {
        handleGetSequences(
          res.data.linkedFile.fileId ? res.data.linkedFile.fileId : ""
        );
      }
    } catch (error) {
      toast.error("error getting details");
    }
  };

  useEffect(() => {
    handleGetProjectDetails();
  }, [projectId]);

  const handleNextSequence = () => {
    const index = sequenceList.findIndex(
      (sequence) => sequence.id === selectedSequence?.id
    );
    if (sequenceList[index + 1]?.breakdownVersion) {
      setIsShotsListLoading(true);
      setSelectedSequence(sequenceList[index + 1]);
    } else {
      toast.error("Next sequence didn't have selected version");
    }
  };

  const handlePreviousSequence = () => {
    const index = sequenceList.findIndex(
      (sequence) => sequence.id === selectedSequence?.id
    );
    if (sequenceList[index - 1]?.breakdownVersion) {
      setIsShotsListLoading(true);
      setSelectedSequence(sequenceList[index - 1]);
    } else {
      toast.error("Previous sequence didn't have selected version");
    }
  };

  interface handleStoryboardGenerationType {
    type: string;
    description: string;
    promptStyle: any;
    cameraOption: string;
    cameraSettings: string;
  }

  // type: "Single" || "all"
  async function handleStoryboardGeneration({
    type,
    description,
    promptStyle,
    cameraOption,
    cameraSettings,
  }: handleStoryboardGenerationType) {
    // takes list of ids and generates initiates a storyboard generation process.
    // "all" will get all the ids.
    // 1) open a modal and get the style type and store it in the state
    // 2) based on the "type" single or multiple create a payload
    // 3) call a new api call to send a generation request.
    setIsThumbnailsGenerating(true);
    let requestPayload: any = {};
    function replaceCharacterWithAppearance(
      characterAppearance: any,
      description: string
    ) {
      for (let character in characterAppearance) {
        if (characterAppearance.hasOwnProperty(character)) {
          const appearance = characterAppearance[character];
          description = description.replaceAll(character, `(${appearance})`);
        }
      }
      return description;
    }
    if (type === "all") {
      requestPayload.projectId = projectId;
      requestPayload.sequenceId = sequenceId;
      requestPayload.breakdownId = selectedSequence?.selectedBreakdown;
      requestPayload.shot_list = [];
      //collect main object of all selected shots;
      const localShotList = shotList.filter((eachShot: any) =>
        selectedShots.includes(eachShot.shotId)
      );

      for (let i = 0; i < localShotList.length; i++) {
        const currentShot: any = { ...localShotList[i] };
        let cameraPrompt;
        if (cameraOption !== "" && cameraOption !== "") {
          cameraPrompt = cameraOption + " " + cameraSettings;
        } else {
          cameraPrompt = currentShot.cameraPosition;
        }
        let shotDescription = currentShot.description;
        //sanitize character names with their appearance
        if (currentShot.shotMeta?.characterAppearance) {
          // we have latest char meta.
          shotDescription = replaceCharacterWithAppearance(
            currentShot.shotMeta?.characterAppearance,
            currentShot.description
          );
        }

        currentShot.Midjourney_Prompt =
          `Style:(${
            promptStyle?.prompt || "cinematic"
          }) ${shotDescription} Important Note:${description} Camera:(((${cameraPrompt}: 1.2))) cameraAction:(((${
            currentShot.cameraPosition
          }: 1.0)))` +
          `Location description:(${currentShot.Midjourney_Prompt})`;
        currentShot.userDescription = `${cameraPrompt} ${description}`;
        requestPayload.shot_list.push({
          Midjourney_Prompt: currentShot.Midjourney_Prompt,
          projectId: currentShot.projectId,
          shotId: currentShot.shotId,
          sequenceId: currentShot.sequenceId,
          breakdownId: currentShot.breakdownId,
        });
      }
    } else {
      requestPayload.projectId = projectId;
      requestPayload.sequenceId = sequenceId;
      requestPayload.breakdownId = selectedSequence?.selectedBreakdown;
      requestPayload.shot_list = [];
      const currentShot: any = singleSelectedShot;
      let cameraPrompt;
      if (cameraOption !== "" && cameraOption !== "") {
        cameraPrompt = cameraOption + " " + cameraSettings;
      } else {
        cameraPrompt = currentShot.cameraPosition;
      }

      let shotDescription = currentShot.description;
      //sanitize character names with their appearance
      if (currentShot.shotMeta?.characterAppearance) {
        // we have latest char meta.
        shotDescription = replaceCharacterWithAppearance(
          currentShot.shotMeta?.characterAppearance,
          currentShot.description
        );
      }
      console.log(shotDescription);
      
      currentShot.Midjourney_Prompt =
        `Style:(${promptStyle.prompt}) ${shotDescription}  Important Note:${description} Camera:(((${cameraPrompt}: 1.2)))` +
        `Location background description:(${currentShot.Midjourney_Prompt})`;
      currentShot.userDescription = `${cameraPrompt} ${description}`;
      requestPayload.shot_list.push({
        Midjourney_Prompt: currentShot.Midjourney_Prompt,
        projectId: currentShot.projectId,
        shotId: currentShot.shotId,
        sequenceId: currentShot.sequenceId,
        breakdownId: currentShot.breakdownId,
      });

      // when the type is single
      // we read ids
    }
    // now we have the request payload.
    try {
    
       await generateStoryBoard(requestPayload);
      setSequenceThumbnailOpen(false);
      updateRefreshCounter();
      getCredits();
      setIsThumbnailsGenerating(false);
      setSelectedShots([]);
    } catch (error: any) {
      toast.error("error initiating generation");
      if (error?.response?.data?.errCode === "CREDINSF") {
        setInsufficientCreditModal(true);
      }
      setIsThumbnailsGenerating(false);
    }
    setNewJobRefreshCounter(newJobRefreshCounter + 1);
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.pageHeader}>
        <Box className={classes.headerContainers}>
          <Button
            startIcon={<CaretLeft size={15} />}
            className={classes.btn}
            style={{ textTransform: "none" }}
            onClick={() => navigate(`/project/${projectId}/storyboard`)}
          >
            Sequences
          </Button>
        </Box>
        <Box className={classes.headerContainers}>
          <StoryBoardIcon fill="#F9F9F9" />
          <Typography>Story Boarding</Typography>
        </Box>
        <Box className={classes.headerContainers}></Box>
      </Box>
      <Box className={classes.boxContainerTwo}>
        <Box className={classes.shotContainerHeader}>
          <Typography className={classes.flexAndGap}>
            <div className={classes.dot} />
            {selectedSequence?.sceneTitle}
          </Typography>
          <Typography>
            Shots - {selectedSequence?.breakdownShotCount}
          </Typography>
          <Tooltip title="Bulk Generate" placement="top">
            <Button
              onClick={() => {
                handleSequenceThumbnailModal();
              }}
              className={classes.generateAllButton}
              disabled={selectedShots?.length ? false : true}
              sx={{
                ":disabled": { backgroundColor: "#252A38 !important" },
              }}
            >
              <StoryBoardIcon fill="#F9F9F9" />
            </Button>
          </Tooltip>
        </Box>
        <Box>
          <StoryBoardProgress
            projectId={projectId}
            sequenceId={sequenceId}
            breakdownId={selectedSequence?.selectedBreakdown || null}
            fetchShotListCallback={() => {
              handleGetShotList(selectedSequence?.selectedBreakdown);
              // makes a new get request for fetching list of shots again.
            }}
            newJobRefreshCounter={newJobRefreshCounter}
            isBoardGenerationInProg={isBoardGenerationInProg}
            setIsBoardGenerationInProg={setIsBoardGenerationInProg}
          />
        </Box>
        <Box sx={{ pr: "6px" }}>
          <Grid container columnSpacing={2} columns={18}>
            <Grid item sm={4} md={4} lg={4} xl={3} sx={{ width: "100%" }}>
              <Box>
                <Typography sx={{ color: "#606479" }}>
                  Shotboards created -{" "}
                  <span style={{ color: "white" }}>{shotList?.length}</span>
                </Typography>
              </Box>
            </Grid>

            <Grid item sm={14} md={14} lg={14} xl={15}>
              <Box className={classes.flexBetween}>
                <Box className={classes.flexAndGap}>
                  {shotList?.length ? (
                    <Button
                      className={classes.selectAllButton}
                      onClick={() =>
                        handleSelectAndUnselectAllShots("selectAll")
                      }
                    >
                      <SelectAllIcon />
                      <Typography>Select All</Typography>
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button
                    className={classes.clearAllButton}
                    onClick={() =>
                      handleSelectAndUnselectAllShots("unselectAll")
                    }
                    sx={{ display: selectedShots?.length ? "flex" : "none" }}
                  >
                    <X size={20} fill="#6C65D9" />
                    <Typography>Clear All</Typography>
                  </Button>
                </Box>
                <Typography sx={{ color: "#606479 " }}>
                  Shots Selected -{" "}
                  <span style={{ color: "white" }}>
                    {selectedShots?.length}
                  </span>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{ height: isBoardGenerationInProg ? "88%" : "92%" }}
          className={classes.scrollContainerTwo}
        >
          {!isShotsListLoading ? (
            <>
              {shotList.map((shot: SingleShotType, index: number) => {
                return (
                  <SingleShotOfStoryBoard
                    key={shot.shotId}
                    shot={shot}
                    onSingleShotGeneration={() => {
                      setGenerationType("single");
                      setSingleSelectedShot(shot);
                      setIsSingleShotGenerationModalOpen(true);
                    }}
                    handleOpenShotVersion={(shot) =>
                      handleOpenShotVersion(shot)
                    }
                    selectedShots={selectedShots}
                    handleSelectUnSelectShots={handleSelectUnSelectShot}
                    updateRefreshCounter={updateRefreshCounter}
                  />
                );
              })}
            </>
          ) : (
            <Loader />
          )}
        </Box>
        <Box className={classes.flexBetween} sx={{ mt: "8px" }}>
          <OutlinedButton
            text="Previous Sequence"
            disabled={
              sequenceList.findIndex(
                (sequence) => sequence.id === selectedSequence?.id
              ) === 0
                ? true
                : false
            }
            handleClick={() => handlePreviousSequence()}
            fullWidth={false}
            minWidth={120}
            startIcon={<PreviousIcon />}
          />
          <Box sx={{ display: "flex", gap: "36px" }}>
            <FilledButton
              text="Go To Breakdown"
              handleClick={() => navigate(ROUTES.PROJECTS.VIEW(projectId))}
              disabled={
                sequenceList.findIndex(
                  (sequence) => sequence.id === selectedSequence?.id
                ) ===
                sequenceList?.length - 1
                  ? true
                  : false
              }
              fullWidth={false}
              minWidth={100}
              isLoading={false}
            />
            <FilledButton
              text="Export Preview"
              disabled={false}
              handleClick={() =>
                navigate(ROUTES.EXPORT_PREVIEW.VIEW(projectId))
              }
              fullWidth={false}
              minWidth={80}
              startIcon={
                <ResponsivePhosphorIcon
                  sizes={{ md: 12, lg: 16, xl: 20 }}
                  icon="SignOut"
                  fill="white"
                />
              }
              isLoading={false}
            />
          </Box>
          <OutlinedButton
            text="Next Sequence"
            disabled={false}
            handleClick={() => handleNextSequence()}
            fullWidth={false}
            minWidth={120}
            endIcon={<NextIcon />}
          />
        </Box>
        <Slide direction="left" in={shotDetailsToSelectVersion.isOpen}>
          <div
            style={{
              display: shotDetailsToSelectVersion.isOpen ? "block" : "none",
            }}
            className={classes.floatingDiv}
          >
            <div
              style={{
                height: "90%",
                padding: "5px",
              }}
            >
              <SelectStoryBoardShotVersionModal
                handleClose={() =>
                  setShotDetailsToSelectVersion({
                    isOpen: false,
                    shotDetail: null,
                  })
                }
                shotDetail={shotDetailsToSelectVersion.shotDetail}
                updateRefreshCounter={updateRefreshCounter}
              />
            </div>
          </div>
        </Slide>
      </Box>
      <Modal
        open={isSequenceThumbnailOpen}
        onClose={handleSequenceThumbnailModal}
        className={classes.flexCenter}
      >
        <SequenceThumbnailGenerateModal
          handleSequenceThumbnailModal={handleSequenceThumbnailModal}
          shotDescription={""}
          shotListLength={selectedShots.length}
          handleSubmit={(promptStyle, description) => {
            setGenerationType("all");
            handleStoryboardGeneration({
              type: "all",
              description: description,
              promptStyle: promptStyle,
              cameraOption: "",
              cameraSettings: "",
            });
          }}
          isThumbnailGenerating={isThumbnailsGenerating}
        />
      </Modal>
      <Modal
        open={isSingleShotGenerationModalOpen}
        onClose={() => setIsSingleShotGenerationModalOpen(false)}
        className={classes.flexCenter}
      >
        <SingleShotThumbnailGenerateModal
          handleShotsThumbnailModal={() =>
            setIsSingleShotGenerationModalOpen(false)
          }
          singleSelectedShot={singleSelectedShot}
          handleSubmit={(
            promptStyle,
            description,
            cameraOption,
            cameraSettings
          ) => {
            setGenerationType("single");
            handleStoryboardGeneration({
              type: "single",
              description: description,
              promptStyle: promptStyle,
              cameraOption,
              cameraSettings,
            });
            //saves id of storyBoardStyle after generating storyboard
            localStorage.setItem("storyBoardStyle", promptStyle.id);
            setIsSingleShotGenerationModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={insufficientCreditModal}
        onClose={() => setInsufficientCreditModal(false)}
        className={classes.flexCenter}
      >
        <InsufficientCreditModal
          handleCancel={() => setInsufficientCreditModal(false)}
        />
      </Modal>
      <Modal open={isThumbnailsGenerating} className={classes.flexCenter}>
        <LoadingAnimationWithProgress
          lottie={StoryBoardAnimatedData}
          textOne="Standby! We’re generating the"
          textTwo="storyboard for your!"
        />
      </Modal>
    </Box>
  );
};

export default SequenceStoryBoardOverview;
