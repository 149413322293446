import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  cardText: {
    fontSize: "12px !important",
    fontFamily: "Poppins !important",
    color: "#8D8E93",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  cardTextContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "90%",
  },
  titleAndText: {
    display: "flex",
    gap: "10px",
  },
  selectorAndShotDetails: {
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
    backgroundColor: "#1B1D28",
    padding: "12px 8px",
    borderRadius: "5px",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
  textBtnContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  thumbnail: {
    height: "150px",
    width: "100%",
    borderRadius: "5px",
    // objectFit: "contain",
  },
  leftCaret: {
    position: "absolute",
    top: "48%",
    left: "0px",
    transform: "translateY(-50%)",
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.4) !important",
    boxShadow: "0px 0px 4.5px 0px rgba(0, 0, 0, 0.25) !important",
    height: "85%",
    display: "flex",
    alignItems: "center",
  },
  rightCaret: {
    position: "absolute",
    top: "48%",
    right: "0px",
    transform: "translateY(-50%)",
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.4) !important",
    boxShadow: "0px 0px 4.5px 0px rgba(0, 0, 0, 0.25) !important",
    height: "85%",
    display: "flex",
    alignItems: "center",
  },
  fullScreen: {
    position: "absolute",
    top: "18px",
    right: "0px",
    transform: "translateY(-50%)",
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.5) !important",
    boxShadow: "0px 0px 4.5px 0px rgba(0, 0, 0, 0.25) !important",
    padding: "2px !important",
    borderRadius: "5px !important",
  },
  shotDetails: {
    display: "flex",
    // alignItems: "center",
    // justifyContent: "space-between",
    flexDirection: "column",
    gap: "12px",
    width: "90%",
  },
  shotNo: {
    fontSize: "14px",
    fontFamily: "Poppins",
    color: "#fff",
  },

  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    alignItems: "flex-end",
  },
  regenerateButton: {
    minWidth: "0px !important",
    minHeight: "0px !important",
    padding: "6px !important",
    backgroundColor: "#252A38 !important",
    borderRadius: "5px !important",
  },
  openVersionButton: {
    minWidth: "0px !important",
    minHeight: "0px !important",
    padding: "4px",
    backgroundColor: "transparent !important",
    border: "2px solid #6C65D9 !important",
    borderRadius: "10px !important",
    color: "white !important",
    fontSize: "12px !important",
    width: "125px !important",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default useStyles;
