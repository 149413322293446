import axios from "axios";
import { Character, Location, ProjectIdType } from "../../types/public-types";

const baseUrl = process.env.REACT_APP_BASE_URL;

export interface CharactersResponse {
  size: number;
  characters: Character[];
}

export interface LocationsResponse {
  size: number;
  locations: Location[];
}

export const getAllCharactersApi = async (projectId: ProjectIdType) => {
  const res = await axios.get<CharactersResponse>(
    `${baseUrl + "character/" + projectId}`
  );
  return res;
};

export const getAllLocationsApi = async (projectId: ProjectIdType) => {
  const res = await axios.get<LocationsResponse>(
    `${baseUrl + "location/" + projectId}`
  );
  return res;
};
