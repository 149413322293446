import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { Warning } from "@phosphor-icons/react";
import { SequenceType } from "../../../types/public-types";
import {
  CheckMarkIcon,
  CheckMarkIconTwo,
  GrayDot,
  WhiteDot,
} from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import ResponsivePhosphorIcon from "../../ReusableComponents/SvgAndIcons/ResponsivePhosphorIcon";
interface Props {
  sequenceList: SequenceType[];
  selectedSequence?: SequenceType | null;
  setSelectedSequence: any;
  currentView: string;
  setCurrentView: (viewName: string) => void;
}
const SequenceListView = ({
  sequenceList,
  selectedSequence,
  setSelectedSequence,
  currentView,
  setCurrentView,
}: Props) => {
  return (
    <Box sx={{ pl: 2, pr: 1 }}>
      {sequenceList?.length ? (
        <>
          {sequenceList.map((singleSequence: SequenceType) => (
            <Button
              key={singleSequence.id}
              sx={{
                color:
                  currentView === "sequences" &&
                  singleSequence.id === selectedSequence?.id
                    ? "white"
                    : "gray",
                backgroundColor:
                  currentView === "sequences" &&
                  singleSequence.id === selectedSequence?.id
                    ? "#252A38"
                    : "",
                border:
                  currentView === "sequences" &&
                  singleSequence.id === selectedSequence?.id
                    ? "1px solid #6C65D9"
                    : "",
                borderRadius: "10px",
                width: "100%",
                fontWeight: 500,
                "&:hover": {
                  color: "white",
                },
                paddingLeft: "8px",
                paddingRight: "10px",
                // p: 1,
              }}
              onClick={() => {
                setCurrentView("sequences");
                setSelectedSequence(singleSequence);
              }}
            >
              <Grid container columnSpacing={1} alignItems="center">
                <Grid item md={11} lg={10}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "8px",
                    }}
                  >
                    <Typography>{singleSequence.sceneNumber + " "}</Typography>
                    {singleSequence.id === selectedSequence?.id ? (
                      <WhiteDot />
                    ) : (
                      <GrayDot />
                    )}
                    <Tooltip title={singleSequence.sceneTitle} placement="top">
                      <Typography
                        sx={{
                          textAlign: "start",
                          display: {
                            sm: "none",
                            md: "none",
                            lg: "none",
                            xl: "block",
                          },
                        }}
                      >
                        {singleSequence.sceneTitle
                          .replace("-", "")
                          .slice(0, 20)}
                      </Typography>
                    </Tooltip>
                    <Typography
                      sx={{
                        textAlign: "start",
                        display: {
                          sm: "block",
                          md: "block",
                          lg: "block",
                          xl: "none",
                        },
                      }}
                    >
                      {singleSequence.sceneTitle.replace("-", "").slice(0, 18)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={1} lg={2}>
                  <Box
                    sx={{
                      pr: 2,
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    {singleSequence.selectedBreakdown ? (
                      <>
                        {singleSequence.id === selectedSequence?.id ? (
                          <CheckMarkIconTwo />
                        ) : (
                          <CheckMarkIcon />
                        )}
                      </>
                    ) : (
                      <ResponsivePhosphorIcon
                        sizes={{ md: 12, lg: 16, xl: 20 }}
                        icon="Warning"
                        fill="red"
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Button>
          ))}
        </>
      ) : (
        <p>No Sequence found</p>
      )}
    </Box>
  );
};

export default SequenceListView;
