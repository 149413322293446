import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { X } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Routes";
import {
  getSequenceListApi,
  selectBreakdownVersionApi,
} from "../../../Services/breakdownServices/breakdownServices";
import {
  ProjectIdType,
  SequenceListType,
  SequenceType,
} from "../../../types/public-types";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import Loader from "../../ReusableComponents/Loader/Loader";
import { GenerateNewBreakdownSvg } from "../../ReusableComponents/SvgAndIcons/GenerateNewBreakdownSvg";
import ResponsivePhosphorIcon from "../../ReusableComponents/SvgAndIcons/ResponsivePhosphorIcon";
import { StoryBoardIcon } from "../../ReusableComponents/SvgAndIcons/StoryBoardIcon";
import {
  ArrowDown,
  CharacterIcon,
  GrayDot,
  WhiteDot,
} from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import ConfigureBreakdownModal from "../Modals/ConfigureBreakdownModal";
import useStyles from "./Breakdown.style";
import CharactersViewer from "./CharactersViewer";
import LocationsViewer from "./LocationsViewer";
import SequenceListView from "./SequenceListView";
import ShotsViewer from "./ShotsViewer";

interface Props {
  projectId: ProjectIdType;
  fileId: string;
  setSelectedSequencePages: any;
  setSelectedSequence: any;
  selectedSequence: SequenceType | null;
}

const BreakDown = ({
  projectId,
  fileId,
  setSelectedSequencePages,
  setSelectedSequence,
  selectedSequence,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [sequenceList, setSequenceList] = useState<SequenceListType>([]);
  const [filteredSequenceList, setFilteredSequenceList] =
    useState<SequenceListType>([]);

  const [isSequencesLoaded, setIsSequencesLoaded] = useState<boolean>(false);
  const [isSequencesExpanded, setIsSequencesExpanded] = useState<boolean>(true);
  const [currentView, setCurrentView] = useState("sequences");
  const [sequenceSearchText, setSequenceSearchText] = useState<string>("");
  const [breakdownLevel, setBreakdownLevel] = useState<number>(3);
  const [isConfigureModalOpen, setIsConfigureModalOpen] =
    useState<boolean>(false);
  const [refreshSelectedBreakdownVersion, setRefreshSelectedBreakdownVersion] =
    useState<number>(0);

  const handleGetSequences = async () => {
    try {
      const res = await getSequenceListApi(projectId, fileId);
      if (res?.data?.sequences?.length) {
        setSequenceList(res.data.sequences);
        setFilteredSequenceList(res.data.sequences);
        if (selectedSequence) {
          const selected = res?.data?.sequences?.find(
            (seq: SequenceType) => seq.id === selectedSequence.id
          );
          setSelectedSequence(selected ? selected : null);
          setSelectedSequencePages(selected?.pageNumbers);
        } else {
          setSelectedSequence(res.data.sequences[0]);
          setSelectedSequencePages(res.data.sequences[0]?.pageNumbers);
        }
      } else {
      }
      setIsSequencesLoaded(true);
    } catch (error) {
      setIsSequencesLoaded(true);
      toast.error("Error getting sequences");
    }
  };

  const handleSelectBreakdownVersion = async (id: any) => {
    toast.loading("Selecting Version");
    try {
      const res = await selectBreakdownVersionApi(
        fileId,
        selectedSequence?.id,
        id
      );
      if (res) {
        toast.success("Breakdown Version Selected");
        handleGetSequences();
      }
    } catch (error) {
      toast.error("Error On Select Breakdown");
    }
  };

  useEffect(() => {
    handleGetSequences();
  }, [projectId, fileId, refreshSelectedBreakdownVersion]);

  useEffect(() => {
    if (sequenceSearchText) {
      const filteredSequences = sequenceList.filter((single: SequenceType) =>
        single.sceneTitle
          .toLowerCase()
          .includes(sequenceSearchText.toLowerCase())
      );
      setFilteredSequenceList(filteredSequences);
    } else {
      setFilteredSequenceList(sequenceList);
    }
  }, [sequenceSearchText]);

  const buttonCommonStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: "white",
    mb: 1,
    width: "100%",
    justifyContent: "start",
    borderRadius: "10px",
    p: 1,
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.breakDownText}>
        <Box></Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <GenerateNewBreakdownSvg />
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              fontSize: { md: "16px", lg: "16px", xl: "18px" },
            }}
          >
            Break Down
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
          <Button>
            <ResponsivePhosphorIcon
              sizes={{ md: 22, lg: 24, xl: 28 }}
              icon="ClockCounterClockwise"
              fill="#606479"
            />
          </Button>
          <Button onClick={() => setIsConfigureModalOpen(true)}>
            <ResponsivePhosphorIcon
              sizes={{ md: 22, lg: 24, xl: 28 }}
              icon="Gear"
              fill="#606479"
            />
          </Button>
        </Box>
      </Box>
      <Box className={classes.leftMenuAndRightDetails}>
        <Box
          sx={{
            height: "94%",
            borderRadius: "10px",
          }}
        >
          <Grid container spacing={1} sx={{ height: "100%", width: "100%" }}>
            <Grid item md={3} sm={3} sx={{ height: "100%" }}>
              <Box className={classes.leftSideContents}>
                <Typography className={classes.breakDownElement}>
                  Break Down Elements
                </Typography>

                <Box sx={{ height: "100%" }}>
                  <Box>
                    <Button
                      onClick={() => {
                        setIsSequencesExpanded(!isSequencesExpanded);
                      }}
                      sx={{
                        ...buttonCommonStyle,
                        backgroundColor:
                          currentView === "sequences" ? "#252A38" : "",
                      }}
                    >
                      {isSequencesExpanded ? (
                        <ArrowDown />
                      ) : (
                        <ResponsivePhosphorIcon
                          sizes={{ md: 16, lg: 18, xl: 20 }}
                          icon="CaretRight"
                          fill="white"
                        />
                      )}
                      <ResponsivePhosphorIcon
                        sizes={{ md: 16, lg: 18, xl: 20 }}
                        icon="FilmStrip"
                        fill="white"
                      />
                      <Typography>Sequences</Typography>
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      // paddingLeft: "15px",
                      height: { md: "70%", lg: "72%", xl: "78%" },
                      overflow: "auto",
                      display: isSequencesExpanded ? "block" : "none",
                    }}
                  >
                    <Box className={classes.searchBoxContainer}>
                      <input
                        className={classes.searchInput}
                        placeholder="Search"
                        onChange={(e) => setSequenceSearchText(e.target.value)}
                        value={sequenceSearchText}
                      />
                      {sequenceSearchText ? (
                        <Button
                          sx={{
                            minWidth: "0px",
                            backgroundColor: "#1B1D28",
                            borderRadius: "10px",
                          }}
                          onClick={() => setSequenceSearchText("")}
                        >
                          <X size={18} fill="#606479" />
                        </Button>
                      ) : (
                        ""
                      )}
                    </Box>
                    {isSequencesLoaded ? (
                      <>
                        <SequenceListView
                          selectedSequence={selectedSequence}
                          sequenceList={filteredSequenceList}
                          setSelectedSequence={setSelectedSequence}
                          currentView={currentView}
                          setCurrentView={setCurrentView}
                        />
                      </>
                    ) : (
                      <Loader />
                    )}
                  </Box>
                  <Box>
                    <Button
                      onClick={() => {
                        setCurrentView("characters");
                        setSelectedSequence(null);
                      }}
                      sx={{
                        ...buttonCommonStyle,
                        backgroundColor:
                          currentView === "characters" ? "#252A38" : "",
                        border:
                          currentView === "characters"
                            ? "2px solid #6C65D9"
                            : "",
                      }}
                    >
                      {currentView === "characters" ? (
                        <WhiteDot />
                      ) : (
                        <GrayDot />
                      )}
                      <CharacterIcon />
                      <Typography>Characters</Typography>
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      onClick={() => {
                        setCurrentView("locations");
                        setSelectedSequence(null);
                      }}
                      sx={{
                        ...buttonCommonStyle,
                        backgroundColor:
                          currentView === "locations" ? "#252A38" : "",
                        border:
                          currentView === "locations"
                            ? "2px solid #6C65D9"
                            : "",
                      }}
                    >
                      {currentView === "locations" ? <WhiteDot /> : <GrayDot />}
                      <ResponsivePhosphorIcon
                        sizes={{ md: 12, lg: 16, xl: 20 }}
                        icon="MapPin"
                        fill="white"
                      />
                      <Typography>Locations</Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={9}
              sm={9}
              sx={{ height: { md: "90%", lg: "92%", xl: "98%" } }}
            >
              <Box className={classes.rightSideContainer}>
                {currentView === "sequences" ? (
                  <ShotsViewer
                    selectedSequence={selectedSequence}
                    setSelectedSequencePages={setSelectedSequencePages}
                    projectId={projectId}
                    fileId={fileId}
                    handleSelectBreakdownVersion={handleSelectBreakdownVersion}
                    handleUpdateBreakdownVersionRefresh={() =>
                      setRefreshSelectedBreakdownVersion(
                        refreshSelectedBreakdownVersion + 1
                      )
                    }
                    breakdownLevel={breakdownLevel}
                  />
                ) : currentView === "characters" ? (
                  <CharactersViewer projectId={projectId} />
                ) : (
                  <LocationsViewer projectId={projectId} />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.bottomButtonContainer}>
          <OutlinedButton
            text="Story Board"
            disabled={false}
            handleClick={() => navigate(ROUTES.STORYBOARD.VIEW(projectId))}
            fullWidth={false}
            minWidth={80}
            startIcon={<StoryBoardIcon fill="#F9F9F9" />}
          />
          <FilledButton
            text="Export Preview"
            disabled={false}
            handleClick={() => navigate(ROUTES.EXPORT_PREVIEW.VIEW(projectId))}
            fullWidth={false}
            minWidth={80}
            startIcon={
              <ResponsivePhosphorIcon
                sizes={{ md: 12, lg: 16, xl: 20 }}
                icon="SignOut"
                fill="white"
              />
            }
            isLoading={false}
          />
        </Box>
      </Box>
      <Modal
        open={isConfigureModalOpen}
        className={classes.flexCenter}
        onClose={() => setIsConfigureModalOpen(false)}
      >
        <ConfigureBreakdownModal
          handleClose={() => setIsConfigureModalOpen(false)}
          setBreakdownLevel={setBreakdownLevel}
          breakdownLevel={breakdownLevel}
        />
      </Modal>
    </Box>
  );
};

export default BreakDown;
