import {
  Box,
  Button,
  Grid,
  Modal,
  Radio,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowsOut,
  CaretLeft,
  CaretRight,
  Check,
  MapPin,
  PencilLine,
  Warning,
  X,
} from "@phosphor-icons/react";
import { SetStateAction, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { SingleShotType } from "../../../types/public-types";
import {
  Camera,
  Characters,
} from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import NoStoryBoard from "../../Utilities/NoStoryboard.png";
import useStyles from "./SingleShotOfStoryBoard.styles";

import toast from "react-hot-toast";
import { updateShotApi } from "../../../Services/breakdownServices/breakdownServices";
import { OutlinedButton } from "../../ReusableComponents/Buttons/Buttons";
import ShotEditInput from "../../ReusableComponents/CustomInputField/ShotEditInput";
import { StoryBoardIcon } from "../../ReusableComponents/SvgAndIcons/StoryBoardIcon";
import FullScreenShotImageModal from "./FullScreenShotImageModal";

type Props = {
  shot: SingleShotType;
  onSingleShotGeneration: () => void;
  handleOpenShotVersion: (shot: any) => void;
  selectedShots?: string[];
  handleSelectUnSelectShots: (is: string) => void;
  updateRefreshCounter: () => void;
};

const SingleShotOfStoryBoard = ({
  shot,
  onSingleShotGeneration,
  handleOpenShotVersion,
  selectedShots,
  handleSelectUnSelectShots,
  updateRefreshCounter,
}: Props) => {
  const classes = useStyles();

  const [shotStoryBoardImages, setShotStoryBoardImages] = useState<any>([]);
  const [shotsThumbnailModalOpen, setShotThumbnailModalOpen] =
    useState<boolean>(false);
  const [isShotEditing, setIsShotEditing] = useState<boolean>(false);
  const [editShotText, setEditShotText] = useState(shot.description);
  const [editCharacterText, setEditCharacterText] = useState(shot.characters);
  const [editLocationText, setEditLocationText] = useState(shot.location);
  const [editCameraText, setEditCameraText] = useState(shot.cameraPosition);
  const [fullScreenImageView, setFullScreenImageView] =
    useState<boolean>(false);

  const handleShotsThumbnailModal = () => {
    setShotThumbnailModalOpen(!shotsThumbnailModalOpen);
  };
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const handleUpdateShot = async () => {
    toast.loading("Updating Shot");
    try {
      const updateData = {
        breakdownId: shot.breakdownId,
        shotId: shot.shotId,
        description: editShotText ? editShotText : "",
        cameraPosition: editCameraText ? editCameraText : "",
        characters: editCharacterText ? editCharacterText : "",
        location: editLocationText ? editLocationText : "",
      };
      const res = await updateShotApi(updateData);
      if (res) {
        toast.success("Shot Updated Successfully");
        updateRefreshCounter();
        setIsShotEditing(false);
      }
    } catch (error) {
      toast.error("Error on edit shot");
    }
  };

  useEffect(() => {
    if (shot.storyboardPaths) {
      const listOfImages = shot.storyboardPaths.map(
        (eachBoard) => eachBoard?.storyboardImageURL
      );
      if (listOfImages?.length) {
        setShotStoryBoardImages(listOfImages);
        setSelectedImage(listOfImages[0]);
      }
    }
    return () => {};
  }, [shot]);

  useEffect(() => {
    setIsSelected(
      selectedShots?.some((sin: string) => sin === shot.shotId) || false
    );
  }, [selectedShots, shot]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Grid container spacing={2} columns={18}>
      <Grid item sm={4} md={4} lg={4} xl={3} sx={{ width: "100%" }}>
        <div>
          <div style={{ position: "relative" }}>
            <img
              src={selectedImage || NoStoryBoard}
              style={{
                border: `2px solid ${isSelected ? "#6C65D9" : "transparent"}`,
              }}
              className={classes.thumbnail}
              alt="no img"
            />
            {shotStoryBoardImages.length > 1 && selectedImage ? (
              <>
                <Box className={classes.leftCaret}>
                  <CaretLeft
                    size={25}
                    onClick={() => {
                      if (shotStoryBoardImages.indexOf(selectedImage) !== 0) {
                        setSelectedImage(
                          shotStoryBoardImages[
                            shotStoryBoardImages.indexOf(selectedImage) - 1
                          ]
                        );
                      }
                    }}
                  />
                </Box>
                <Box className={classes.rightCaret}>
                  <CaretRight
                    size={25}
                    onClick={() => {
                      if (
                        shotStoryBoardImages.indexOf(selectedImage) !==
                        shotStoryBoardImages?.length - 1
                      ) {
                        setSelectedImage(
                          shotStoryBoardImages[
                            shotStoryBoardImages.indexOf(selectedImage) + 1
                          ]
                        );
                      }
                    }}
                  />
                </Box>
              </>
            ) : (
              " "
            )}
            {shotStoryBoardImages.length && selectedImage ? (
              <ArrowsOut
                size={20}
                className={classes.fullScreen}
                onClick={() => setFullScreenImageView(true)}
              />
            ) : (
              " "
            )}
          </div>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              {shotStoryBoardImages.length ? (
                <Carousel
                  arrows={false}
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  infinite={false}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  itemClass="carousel-item-padding-20-px"
                >
                  {shotStoryBoardImages.map((image: string) => (
                    <img
                      key={image}
                      onClick={() => setSelectedImage(image)}
                      draggable={false}
                      src={image || NoStoryBoard}
                      alt="no"
                      width="60px"
                      height="40px"
                      style={{
                        border:
                          selectedImage === image
                            ? "2px solid #6C65D9"
                            : "2px solid transparent",
                        borderRadius: "5px",
                      }}
                    />
                  ))}
                </Carousel>
              ) : (
                ""
              )}
            </div>
          </Box>
        </div>
      </Grid>

      <Grid item sm={14} md={14} lg={14} xl={15}>
        <Box
          className={classes.selectorAndShotDetails}
          sx={{
            border: `1px solid ${isSelected ? "#6C65D9" : "transparent"}`,
          }}
        >
          <Box>
            <Radio
              checked={isSelected}
              onClick={() => handleSelectUnSelectShots(shot?.shotId)}
              sx={{
                color: isSelected ? "#6C65D9 !important" : "#606479 !important",
                width: "30px",
              }}
            />
          </Box>
          <Box className={classes.textBtnContainer}>
            <div className={classes.shotDetails}>
              <Grid container spacing={1} columns={18}>
                <Grid item sm={5} md={3} lg={3} xl={3}>
                  <Typography className={classes.shotNo}>
                    {shot.shotNumberText}
                  </Typography>
                </Grid>
                <Grid item sm={13} md={15} lg={15} xl={15}>
                  {isShotEditing ? (
                    <ShotEditInput
                      value={editShotText}
                      setValue={(value: string) => setEditShotText(value)}
                    />
                  ) : (
                    <Typography className={classes.cardText}>
                      {shot.description}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={1} columns={18}>
                <Grid item sm={5} md={3} lg={3} xl={3}>
                  <Typography className={classes.cardText}>
                    <Characters />
                    Characters
                  </Typography>
                </Grid>
                <Grid item sm={13} md={15} lg={15} xl={15}>
                  {isShotEditing ? (
                    <ShotEditInput
                      value={editCharacterText}
                      setValue={(value: string) => setEditCharacterText(value)}
                    />
                  ) : (
                    <Typography className={classes.cardText}>
                      {shot.characters || "N/A"}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={1} columns={18}>
                <Grid item sm={5} md={3} lg={3} xl={3}>
                  <Typography className={classes.cardText}>
                    <MapPin />
                    Location
                  </Typography>
                </Grid>
                <Grid item sm={13} md={15} lg={15} xl={15}>
                  {isShotEditing ? (
                    <ShotEditInput
                      value={editLocationText}
                      setValue={(value: string) => setEditLocationText(value)}
                    />
                  ) : (
                    <Typography className={classes.cardText}>
                      {shot.location}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={1} columns={18}>
                <Grid item sm={5} md={3} lg={3} xl={3}>
                  <Typography className={classes.cardText}>
                    <Camera />
                    Camera Angle
                  </Typography>
                </Grid>{" "}
                <Grid item sm={13} md={15} lg={15} xl={15}>
                  {isShotEditing ? (
                    <ShotEditInput
                      value={editCameraText}
                      setValue={(value: string) => setEditCameraText(value)}
                    />
                  ) : (
                    <Typography className={classes.cardText}>
                      {shot.cameraPosition}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </div>

            <Box className={classes.buttonsContainer}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {shot?.storyboardPaths?.length > 1 &&
                !shot?.selectedStoryboardVersion ? (
                  <Tooltip
                    title="Please choose a version to export"
                    placement="bottom-end"
                    sx={{ border: "2px solid red", borderRadius: "10px" }}
                  >
                    <Warning size={22} fill="red" />
                  </Tooltip>
                ) : (
                  ""
                )}
                {shot?.storyboardPaths?.length > 1 ? (
                  <OutlinedButton
                    text="Open Versions"
                    disabled={false}
                    handleClick={() => handleOpenShotVersion(shot)}
                    fullWidth={false}
                    minWidth={150}
                  />
                ) : (
                  ""
                )}
              </Box>
              <Button
                className={classes.regenerateButton}
                onClick={() => onSingleShotGeneration()}
              >
                <StoryBoardIcon fill="#F9F9F9" />
              </Button>
              <Box className={classes.titleAndText}>
                {isShotEditing ? (
                  <>
                    <X
                      size={22}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#252A38",
                        padding: "2px",
                        borderRadius: "5px",
                      }}
                      onClick={() => setIsShotEditing(false)}
                    />
                    <Check
                      onClick={() => handleUpdateShot()}
                      size={22}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#6C65D9",
                        padding: "2px",
                        borderRadius: "5px",
                      }}
                    />
                  </>
                ) : (
                  <Button
                    onClick={() => setIsShotEditing(true)}
                    sx={{ minWidth: "0px !important" }}
                  >
                    <PencilLine size={20} fill="#606479" />
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>

      <Modal
        open={shotsThumbnailModalOpen}
        onClose={handleShotsThumbnailModal}
        className={classes.flexCenter}
      >
        <></>
      </Modal>
      <Modal
        open={fullScreenImageView}
        onClose={() => setFullScreenImageView(false)}
        className={classes.flexCenter}
      >
        <FullScreenShotImageModal
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          shotStoryBoardImages={shotStoryBoardImages}
          handleClose={() => setFullScreenImageView(false)}
          shot={shot}
        />
      </Modal>
    </Grid>
  );
};

export default SingleShotOfStoryBoard;
