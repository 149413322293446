import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ScriptBreakdownContext } from "../../../Context/ContextDataProvider";
import { ROUTES } from "../../../Routes";
import {
  deleteBreakdownVersionApi,
  deleteShotApi,
  generateNewBreakdownVersion,
  getBreakdownVersionListApi,
  getShotListApi,
  updateShotApi,
} from "../../../Services/breakdownServices/breakdownServices";
import {
  BreakdownVersion,
  BreakdownVersionType,
  ProjectIdType,
  SequenceType,
  ShotListType,
  SingleShotType,
} from "../../../types/public-types";
import ShotEditInput from "../../ReusableComponents/CustomInputField/ShotEditInput";
import DeleteModal from "../../ReusableComponents/DeleteModal/DeleteModal";
import Loader from "../../ReusableComponents/Loader/Loader";
import GenerateBreakdownIndicatorIcon from "../../ReusableComponents/SvgAndIcons/GenerateBreakdownIndicatorIcon";
import { GenerateNewBreakdownSvg } from "../../ReusableComponents/SvgAndIcons/GenerateNewBreakdownSvg";
import ResponsivePhosphorIcon from "../../ReusableComponents/SvgAndIcons/ResponsivePhosphorIcon";
import { StoryBoardIcon } from "../../ReusableComponents/SvgAndIcons/StoryBoardIcon";
import {
  CharacterIcon,
  CoinIconXsmallForShots,
  SelectedDotIcon,
  SelectedRadioIcon,
  WhiteDot,
} from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import { LoadingIconData } from "../../Utilities/Lotties/LoadingIconData";
import LoadingAnimationWithProgress from "../../Utilities/Modals/LoadingAnimationWithProgress";
import AddNewShotModal from "../Modals/AddNewShotModal";
import useStyles from "./Breakdown.style";

interface Props {
  selectedSequence: SequenceType | null;
  projectId: ProjectIdType;
  setSelectedSequencePages: any;
  fileId: string;
  handleSelectBreakdownVersion: (id: string) => void;
  handleUpdateBreakdownVersionRefresh: () => void;
  breakdownLevel: number;
}

type DeleteModalDataType = {
  isModalOpen: boolean;
  breakdownDetails: BreakdownVersion | null;
};

const ShotsViewer = ({
  selectedSequence,
  projectId,
  setSelectedSequencePages,
  fileId,
  handleSelectBreakdownVersion,
  handleUpdateBreakdownVersionRefresh,
  breakdownLevel,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { getCredits } = useContext(ScriptBreakdownContext);
  const [shotsFilter, setShotsFilter] = useState("all");

  const [shotList, setShotList] = useState<ShotListType>([]);
  const [isShotsLoaded, setIsShotsLoaded] = useState<boolean>(false);

  const [breakdownVersionList, setBreakdownVersionList] =
    useState<BreakdownVersionType>([]);
  const [selectedBreakdownVersion, setSelectedBreakdownVersion] =
    useState<BreakdownVersion | null>();
  const [isBreakDownGenerating, setIsBreakDownGenerating] =
    useState<boolean>(false);
  const [deleteProjectData, setDeleteProjectData] =
    useState<DeleteModalDataType>({
      isModalOpen: false,
      breakdownDetails: null,
    });

  const [refreshCounter, setRefreshCounter] = useState<number>(0);
  const [isInfoHovered, setIsInfoHovered] = useState<boolean>(false);
  const [createNewShotModalData, setCreateNewShotModalData] = useState({
    isOpen: false,
    currentShotId: 0,
  });
  const [isBreakDownVersionDeleting, setIsBreakDownVersionDeleting] =
    useState<boolean>(false);

  const handleOpenNewShotModal = (shotId: number) => {
    setCreateNewShotModalData({
      isOpen: true,
      currentShotId: shotId,
    });
  };

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const handleGetShotList = async () => {
    try {
      const res = await getShotListApi(
        selectedBreakdownVersion?.breakdownId
          ? selectedBreakdownVersion?.breakdownId
          : ""
      );
      setShotList(res.data.shots);
      setIsShotsLoaded(true);
    } catch (error) {
      toast.error("Error getting shots");
      setIsShotsLoaded(true);
    }
  };

  const handleGenerateNewBreakdownVersion = async () => {
    toast.loading("Generating new breakdown");
    setIsBreakDownGenerating(true);
    try {
      const res = await generateNewBreakdownVersion(
        projectId,
        selectedSequence?.id,
        fileId,
        breakdownLevel
      );
      toast.success("New Breakdown generated");
      handleGetBreakdownVersionList();
      setIsBreakDownGenerating(false);
      handleUpdateBreakdownVersionRefresh();
      getCredits();
    } catch (error) {
      toast.error("Error on breakdown generation");
      setIsBreakDownGenerating(false);
    }
  };

  const handleGetBreakdownVersionList = async () => {
    setIsShotsLoaded(false);
    try {
      const res = await getBreakdownVersionListApi(
        projectId,
        selectedSequence?.id
      );
      if (res.data.breakdowns?.length) {
        setBreakdownVersionList(res?.data?.breakdowns);
        /* if(selectedBreakdownVersion?.breakdownId &&
          selectedSequence?.id === selectedBreakdownVersion?.sequenceId){ */
        if (selectedSequence?.selectedBreakdown) {
          const prevSelected = res?.data?.breakdowns?.find(
            (singleBreakDown: BreakdownVersion) =>
              singleBreakDown.breakdownId ===
              selectedSequence?.selectedBreakdown
          );
          setSelectedBreakdownVersion(prevSelected);
        } else {
          setSelectedBreakdownVersion(
            res?.data?.breakdowns[res?.data?.breakdowns?.length - 1]
          );
        }
      } else {
        setBreakdownVersionList([]);
        setIsShotsLoaded(true);
        setShotList([]);
      }
    } catch (error) {
      toast.error("Error getting breakdown list");
    }
  };

  const handleVersionChange = ({
    type,
    pageNo,
  }: {
    type?: string;
    pageNo?: number;
  }) => {
    toast.loading("Changing Version");
    setIsShotsLoaded(false);
    const index = breakdownVersionList.indexOf(selectedBreakdownVersion!);

    if (type === "prev") {
      index > 0 && setSelectedBreakdownVersion(breakdownVersionList[index - 1]);
    } else if (type === "next") {
      index < breakdownVersionList.length &&
        setSelectedBreakdownVersion(breakdownVersionList[index + 1]);
    } else {
      //@ts-ignore
      setSelectedBreakdownVersion(breakdownVersionList[pageNo]);
    }
  };

  useEffect(() => {
    if (selectedSequence) {
      setSelectedBreakdownVersion(null);
      handleGetBreakdownVersionList();
      setSelectedSequencePages(selectedSequence.pageNumbers);
    }
  }, [selectedSequence]);

  useEffect(() => {
    if (selectedBreakdownVersion?.breakdownId) {
      handleGetShotList();
    }
  }, [selectedBreakdownVersion, refreshCounter]);

  // open&close delete modal
  const handleOpenDeleteModal = (details: BreakdownVersion) => {
    setDeleteProjectData({
      isModalOpen: true,
      breakdownDetails: details,
    });
  };

  const handleCloseDeleteModal = () => {
    setDeleteProjectData({
      isModalOpen: false,
      breakdownDetails: null,
    });
  };

  const handleDeleteBreakdown = async () => {
    setIsBreakDownVersionDeleting(true);
    toast.loading("Deleting Version");
    try {
      const res = await deleteBreakdownVersionApi(
        selectedBreakdownVersion?.breakdownId!
      );
      if (res) {
        handleCloseDeleteModal();
        handleGetBreakdownVersionList();
        setIsBreakDownVersionDeleting(false);
      }
      toast.success("Deleted Breakdown Version");
    } catch (error) {
      setIsBreakDownVersionDeleting(false);
      toast.error("Error on delete breakdown");
    }
  };

  const commonButtonSx = {
    backgroundColor: "#6C65D9",
    color: "white",
    borderRadius: "5px",
    py: "2px",
    fontSize: {
      xs: "8px !important",
      sm: "8px !important",
      md: "8px !important",
      lg: "10px !important",
      xl: "12px !important",
    },
  };
  const commonUnselectedButtonSx = {
    color: "#606479",
    fontSize: {
      xs: "8px !important",
      sm: "8px !important",
      md: "8px !important",
      lg: "10px !important",
      xl: "12px !important",
    },
  };

  return (
    <>
      <Box className={classes.rightSideTitle}>
        <Typography className={classes.dotAndText}>
          <WhiteDot />
          <span>{selectedSequence?.sceneTitle}</span>
        </Typography>
        <Typography>Shot-{shotList.length}</Typography>
        <Tooltip title="Generate Breakdown Version" placement="top">
          <Button
            variant="contained"
            className={classes.generateBreakdownButton}
            onClick={() => handleGenerateNewBreakdownVersion()}
          >
            <GenerateNewBreakdownSvg />
            <Chip
              sx={{
                height: { md: "22px", lg: "22px", xl: "30px" },
                borderRadius: "27px",
                pl: "2px",
              }}
              avatar={<CoinIconXsmallForShots />}
              label="2"
              variant="outlined"
            />
            {/* <Typography>Generate Breakdown</Typography> */}
          </Button>
        </Tooltip>
      </Box>
      {isShotsLoaded && shotList?.length ? (
        <>
          <Box className={classes.rightSideTools}>
            <Box>
              <Tooltip title="Select Version" placement="top">
                <FormControlLabel
                  sx={{ ...commonUnselectedButtonSx }}
                  control={
                    <Checkbox
                      icon={
                        <ResponsivePhosphorIcon
                          sizes={{ md: 16, lg: 18, xl: 22 }}
                          icon="Circle"
                          fill="#6C65D9"
                        />
                      }
                      checkedIcon={<SelectedRadioIcon />}
                      disabled={!selectedBreakdownVersion?.breakdownId}
                      onClick={() => {
                        if (
                          selectedSequence?.selectedBreakdown !==
                          selectedBreakdownVersion?.breakdownId
                        ) {
                          handleSelectBreakdownVersion(
                            selectedBreakdownVersion?.breakdownId!
                          );
                        }
                      }}
                      checked={Boolean(
                        selectedSequence?.selectedBreakdown?.length &&
                          selectedSequence?.selectedBreakdown ===
                            selectedBreakdownVersion?.breakdownId
                      )}
                    />
                  }
                  label="Select Version"
                />
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Button
                sx={{ minWidth: "20px", color: "white" }}
                onClick={() => handleVersionChange({ type: "prev" })}
                disabled={Boolean(
                  breakdownVersionList?.indexOf(selectedBreakdownVersion!) ===
                    0 ||
                    !selectedBreakdownVersion ||
                    breakdownVersionList?.length <= 1
                )}
              >
                <CaretLeft size={18} />
              </Button>
              <Box
                sx={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                {breakdownVersionList?.map(
                  (sin: BreakdownVersion, index: number) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop:
                          selectedSequence?.selectedBreakdown ===
                          sin.breakdownId
                            ? "-15px"
                            : "",
                      }}
                    >
                      <div
                        style={{
                          marginBottom: "-8px",
                          display:
                            selectedSequence?.selectedBreakdown ===
                            sin.breakdownId
                              ? "block"
                              : "none",
                        }}
                      >
                        <SelectedDotIcon />
                      </div>
                      <Typography
                        onClick={() => handleVersionChange({ pageNo: index })}
                        sx={{
                          backgroundColor:
                            selectedBreakdownVersion?.breakdownId ===
                            sin.breakdownId
                              ? "#6C65D9"
                              : "",
                          px: 1,
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        {index + 1}
                      </Typography>
                    </div>
                  )
                )}
              </Box>
              <Button
                sx={{ minWidth: "20px", color: "white" }}
                onClick={() => handleVersionChange({ type: "next" })}
                disabled={Boolean(
                  breakdownVersionList?.indexOf(selectedBreakdownVersion!) ===
                    breakdownVersionList?.length - 1 ||
                    breakdownVersionList?.length <= 1
                )}
              >
                <CaretRight size={18} />
              </Button>
            </Box>

            <Box>
              {selectedSequence?.selectedBreakdown?.length &&
                selectedSequence?.selectedBreakdown ===
                  selectedBreakdownVersion?.breakdownId && (
                  <Tooltip title="Go to Story Board" placement="top">
                    <Button
                      sx={{
                        minWidth: "0px",
                        color: "white",
                        backgroundColor: "#1B1D28",
                        padding: "4px",
                      }}
                      onClick={() =>
                        navigate(
                          ROUTES.STORYBOARD.SEQUENCE(
                            projectId,
                            selectedSequence.id
                          )
                        )
                      }
                      disabled={!selectedBreakdownVersion?.breakdownId}
                    >
                      <StoryBoardIcon fill="#F9F9F9" />
                    </Button>
                  </Tooltip>
                )}
              <Tooltip title="Delete Breakdown" placement="top">
                <Button
                  sx={{ minWidth: "0px", color: "white", paddingRight: "0px" }}
                  onClick={() =>
                    handleOpenDeleteModal(selectedBreakdownVersion!)
                  }
                  disabled={!selectedBreakdownVersion?.breakdownId}
                >
                  <ResponsivePhosphorIcon
                    sizes={{ md: 16, lg: 20, xl: 24 }}
                    icon="Trash"
                    fill="red"
                  />
                </Button>
              </Tooltip>
            </Box>
          </Box>
          <Box
            className={classes.flexBetween}
            sx={{
              pb: 1,
            }}
          >
            <Box />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Button
                onClick={() => setShotsFilter("all")}
                sx={
                  shotsFilter === "all"
                    ? {
                        ...commonButtonSx,
                      }
                    : { ...commonUnselectedButtonSx }
                }
              >
                <ResponsivePhosphorIcon
                  sizes={{ md: 12, lg: 16, xl: 20 }}
                  icon="CheckSquareOffset"
                  fill={shotsFilter === "all" ? "white" : "#606479"}
                />
                <span style={{ paddingLeft: "6px" }}>All</span>
              </Button>
              <Button
                onClick={() => setShotsFilter("shots")}
                sx={
                  shotsFilter === "shots"
                    ? {
                        ...commonButtonSx,
                      }
                    : { ...commonUnselectedButtonSx }
                }
              >
                <ResponsivePhosphorIcon
                  sizes={{ md: 12, lg: 16, xl: 20 }}
                  icon="FilmSlate"
                  fill={shotsFilter === "shots" ? "white" : "#606479"}
                />
                <span style={{ paddingLeft: "6px" }}>Shots</span>
              </Button>
              <Button
                onClick={() => setShotsFilter("characters")}
                sx={
                  shotsFilter === "characters"
                    ? {
                        ...commonButtonSx,
                      }
                    : { ...commonUnselectedButtonSx }
                }
              >
                <CharacterIcon />
                <span style={{ paddingLeft: "6px" }}>Characters</span>
              </Button>
              <Button
                onClick={() => setShotsFilter("location")}
                sx={
                  shotsFilter === "location"
                    ? {
                        ...commonButtonSx,
                      }
                    : { ...commonUnselectedButtonSx }
                }
              >
                <ResponsivePhosphorIcon
                  sizes={{ md: 12, lg: 16, xl: 20 }}
                  icon="MapPin"
                  fill={shotsFilter === "location" ? "white" : "#606479"}
                />
                <span style={{ paddingLeft: "6px" }}>Location</span>
              </Button>
              <Button
                onClick={() => setShotsFilter("camera")}
                sx={
                  shotsFilter === "camera"
                    ? {
                        ...commonButtonSx,
                      }
                    : { ...commonUnselectedButtonSx }
                }
              >
                <ResponsivePhosphorIcon
                  sizes={{ md: 12, lg: 16, xl: 20 }}
                  icon="VideoCamera"
                  fill={shotsFilter === "camera" ? "white" : "#606479"}
                />
                <span style={{ paddingLeft: "6px" }}>Camera Angle</span>
              </Button>
            </Box>
            <Box>
              <Button
                sx={{ paddingRight: "6px" }}
                onMouseEnter={() => setIsInfoHovered(true)}
                onMouseLeave={() => setIsInfoHovered(false)}
              >
                <ResponsivePhosphorIcon
                  sizes={{ md: 12, lg: 18, xl: 24 }}
                  icon="Info"
                  fill="#606479"
                />
              </Button>
              {isInfoHovered && (
                <Box
                  sx={{
                    position: "absolute",
                    left: "80%", // Adjust as needed
                    top: "28%",
                    backgroundColor: "#252A38",
                    padding: "10px",
                    border: "2px solid #6C65D9",
                    borderRadius: "10px",
                    width: "230px",
                    zIndex: 999,
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#6C65D9",
                        fontSize: "20px !important",
                        fontWeight: 600,
                      }}
                    >
                      Sequence Info
                    </Typography>
                    <Box className={classes.flexBetween}>
                      <Typography
                        sx={{ color: "#606479", textDecoration: "underline" }}
                      >
                        Category Name
                      </Typography>
                      <Typography
                        sx={{ color: "#606479", textDecoration: "underline" }}
                      >
                        Count
                      </Typography>
                    </Box>
                    <Grid container spacing={1} justifyContent="space-between">
                      <Grid item md={6}>
                        <Typography>Characters</Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography sx={{ textAlign: "center" }}>:</Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography sx={{ textAlign: "end" }}>6</Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} justifyContent="space-between">
                      <Grid item md={6}>
                        <Typography>Locations</Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography sx={{ textAlign: "center" }}>:</Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography sx={{ textAlign: "end" }}>6</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </>
      ) : (
        ""
      )}
      <Box
        className={classes.shortListContainer}
        sx={{ height: { md: "86%", lg: "86%", xl: "83%" } }}
      >
        {isShotsLoaded ? (
          <>
            {shotList.length ? (
              <>
                {shotList.map((singleShot: any, index) => (
                  <SingleShotDetails
                    key={singleShot.id}
                    singleShot={singleShot}
                    shotsFilter={shotsFilter}
                    updateRefreshCounter={updateRefreshCounter}
                    breakdownId={selectedBreakdownVersion?.breakdownId}
                    handleOpenNewShotModal={handleOpenNewShotModal}
                    index={index}
                  />
                ))}
              </>
            ) : (
              <div
                style={{
                  height: "100%",
                }}
                className={classes.flexCenter}
              >
                <GenerateBreakdownIndicatorIcon />
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Box>

      <Modal open={isBreakDownGenerating} className={classes.modal}>
        <LoadingAnimationWithProgress
          lottie={LoadingIconData}
          textOne="Please wait, while we generate"
          textTwo="the breakdown for you"
        />
      </Modal>
      <div>
        <Modal
          open={deleteProjectData?.isModalOpen}
          onClose={() => handleCloseDeleteModal()}
          className={classes.flexCenter}
        >
          <DeleteModal
            handleDelete={handleDeleteBreakdown}
            name={deleteProjectData?.breakdownDetails?.breakdownVersion.toString()}
            type="Breakdown version"
            handleCloseDeleteModal={handleCloseDeleteModal}
            isDeleting={isBreakDownVersionDeleting}
          />
        </Modal>
      </div>
      <Modal
        open={createNewShotModalData.isOpen}
        className={classes.flexCenter}
        onClose={() =>
          setCreateNewShotModalData({ isOpen: false, currentShotId: 0 })
        }
      >
        <AddNewShotModal
          projectId={projectId}
          sequenceId={selectedSequence?.id}
          breakdownId={selectedBreakdownVersion?.breakdownId}
          handleClose={() =>
            setCreateNewShotModalData({ isOpen: false, currentShotId: 0 })
          }
          updateRefreshCounter={updateRefreshCounter}
          previousShotNumber={createNewShotModalData.currentShotId}
          shotList={shotList}
        />
      </Modal>
    </>
  );
};

export default ShotsViewer;

const SingleShotDetails = ({
  singleShot,
  shotsFilter,
  updateRefreshCounter,
  breakdownId,
  handleOpenNewShotModal,
  index,
}: {
  singleShot: SingleShotType;
  shotsFilter: string;
  updateRefreshCounter: () => void;
  breakdownId?: string;
  handleOpenNewShotModal: (shotId: number) => void;
  index: number;
}) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [editShotText, setEditShotText] = useState(singleShot.description);
  const [editCharacterText, setEditCharacterText] = useState(
    singleShot.characters
  );
  const [editLocationText, setEditLocationText] = useState(singleShot.location);
  const [editCameraText, setEditCameraText] = useState(
    singleShot.cameraPosition
  );
  const [isHovered, setIsHovered] = useState(false);
  const [deleteSingleShotModal, setDeleteSingleShotModal] =
    useState<boolean>(false);
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
  const shotInfoToDisplay: string[] = [
    "vfxRequired",
    "cameraAngle",
    "focalLength",
    "rackFocus",
    "shotType",
    "shotEmotion",
    "voiceOver",
  ];

  const handleUpdateShot = async () => {
    toast.loading("Updating Shot");
    try {
      const updateData = {
        breakdownId: breakdownId,
        shotId: singleShot.shotId,
        description: editShotText ? editShotText : "",
        cameraPosition: editCameraText ? editCameraText : "",
        location: editLocationText ? editLocationText : "",
      };
      const res = await updateShotApi(updateData);
      if (res) {
        toast.success("Shot Updated Successfully");
        updateRefreshCounter();
        setIsEditing(false);
      }
    } catch (error) {
      toast.error("Error on edit shot");
    }
  };

  const [isShotDeleting, setIsShotDeleting] = useState<boolean>(false);

  const handleDeleteSingleShot = async () => {
    setIsShotDeleting(true);
    try {
      const promise = toast.promise(
        deleteShotApi({
          breakdownId: singleShot?.breakdownId,
          shotId: singleShot.shotId,
        }),
        {
          loading: "Deleting Shot",
          success: "Successfully deleted shot",
          error: "Error on delete shot",
        }
      );
      const res = await promise;
      updateRefreshCounter();
      setDeleteSingleShotModal(false);
      setIsShotDeleting(false);
    } catch (error) {
      setIsShotDeleting(false);
      toast.error("Error on delete shot");
    }
  };
  return (
    <div>
      <Grid
        container
        spacing={1}
        className={classes.shortListCard}
        columns={{ md: 12, lg: 14, xl: 18 }}
      >
        <Grid item md={10} lg={12} xl={16}>
          <Grid container spacing={1} columns={{ md: 12, lg: 12, xl: 18 }}>
            <Grid
              item
              md={3}
              lg={3}
              xl={4}
              sx={{
                display: "flex",
                alignItems: "start",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  color: singleShot?.source === "user" ? "#6C65D9" : "",
                }}
                variant="h6"
              >
                {singleShot.shotNumberText ? singleShot.shotNumberText : "none"}
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              lg={9}
              xl={14}
              sx={{
                display:
                  shotsFilter === "all" || shotsFilter === "shots"
                    ? "block"
                    : "none",
              }}
            >
              {isEditing ? (
                <ShotEditInput
                  value={editShotText}
                  setValue={(value: string) => setEditShotText(value)}
                />
              ) : (
                <Typography sx={{ color: "#8A8B90" }}>
                  {singleShot.description ? singleShot.description : "none"}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={3}
              lg={3}
              xl={4}
              className={
                shotsFilter === "all"
                  ? classes.shortListMenuItem
                  : classes.shortListItemHide
              }
            >
              <ResponsivePhosphorIcon
                sizes={{ md: 12, lg: 16, xl: 20 }}
                icon="Users"
                fill="#606479"
              />
              <Typography>Character</Typography>
            </Grid>
            <Grid
              item
              md={9}
              lg={9}
              xl={14}
              sx={{
                display:
                  shotsFilter === "all" || shotsFilter === "characters"
                    ? "block"
                    : "none",
              }}
            >
              {isEditing ? (
                <ShotEditInput
                  value={editCharacterText}
                  setValue={(value: string) => setEditCharacterText(value)}
                />
              ) : (
                <Typography sx={{ color: "#8A8B90" }}>
                  {singleShot.characters.length
                    ? singleShot.characters
                    : "none"}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={3}
              lg={3}
              xl={4}
              className={
                shotsFilter === "all"
                  ? classes.shortListMenuItem
                  : classes.shortListItemHide
              }
            >
              <ResponsivePhosphorIcon
                sizes={{ md: 12, lg: 16, xl: 20 }}
                icon="MapPin"
                fill="#606479"
              />
              <Typography>Location</Typography>
            </Grid>
            <Grid
              item
              md={9}
              lg={9}
              xl={14}
              sx={{
                display:
                  shotsFilter === "all" || shotsFilter === "location"
                    ? "block"
                    : "none",
              }}
            >
              {isEditing ? (
                <ShotEditInput
                  value={editLocationText}
                  setValue={(value: string) => setEditLocationText(value)}
                />
              ) : (
                <Typography sx={{ color: "#8A8B90" }}>
                  {singleShot.location?.length ? singleShot.location : "none"}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={3}
              lg={3}
              xl={4}
              className={
                shotsFilter === "all"
                  ? classes.shortListMenuItem
                  : classes.shortListItemHide
              }
            >
              <ResponsivePhosphorIcon
                sizes={{ md: 12, lg: 16, xl: 20 }}
                icon="VideoCamera"
                fill="#606479"
              />
              <Typography>Camera Angle</Typography>
            </Grid>
            <Grid
              item
              md={9}
              lg={9}
              xl={14}
              sx={{
                display:
                  shotsFilter === "all" || shotsFilter === "camera"
                    ? "block"
                    : "none",
              }}
            >
              {isEditing ? (
                <ShotEditInput
                  value={editCameraText}
                  setValue={(value: string) => setEditCameraText(value)}
                />
              ) : (
                <Typography sx={{ color: "#8A8B90" }}>
                  {singleShot.cameraPosition
                    ? singleShot.cameraPosition
                    : "none"}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={2}
          lg={2}
          xl={2}
          sx={{ display: "flex", flexDirection: "column", gap: 4 }}
        >
          <Box className={classes.flexEnd}>
            <Button
              sx={{
                minWidth: "0px",
                color: "white",
              }}
              onMouseEnter={() => setOpenInfoModal(true)}
              onMouseLeave={() => setOpenInfoModal(false)}
            >
              <ResponsivePhosphorIcon
                sizes={{ md: 12, lg: 16, xl: 20 }}
                icon="Info"
                fill="white"
              />
            </Button>
            {openInfoModal && (
              <Box
                className={
                  index === 0 ? classes.firstShotInfo : classes.shotInfo
                }
              >
                <Box>
                  <Typography
                    sx={{
                      color: "#6C65D9",
                      fontSize: "20px !important",
                      fontWeight: 600,
                    }}
                  >
                    {singleShot.shotNumberText
                      ? singleShot.shotNumberText
                      : "none"}
                  </Typography>
                  <Grid container justifyContent="space-between">
                    <Grid item md={5} gap="8px">
                      <Box className={classes.flexBetween}>
                        <Typography
                          sx={{ color: "#606479", textDecoration: "underline" }}
                        >
                          Category Name
                        </Typography>
                      </Box>
                      {shotInfoToDisplay.slice(0, 4).map((shotKey) => {
                        return (
                          <div className={classes.categoryContainer}>
                            <div className={classes.initialContainer2}>
                              <Typography className={classes.textFirstWord}>
                                {shotKey.charAt(0).toUpperCase()}
                                {shotKey.slice(1)}
                              </Typography>
                              <Typography className={classes.secondWord}>
                                :
                              </Typography>
                            </div>
                            <Typography
                              alignSelf="flex-end"
                              className={classes.lastWord}
                            >
                              {singleShot.shotMeta.hasOwnProperty(shotKey)
                                ? singleShot.shotMeta[shotKey] === ""
                                  ? "None"
                                  : singleShot.shotMeta[shotKey]
                                : "None"}
                            </Typography>
                          </div>
                        );
                      })}
                    </Grid>
                    <Divider color="#606479" orientation="vertical" flexItem />
                    <Grid item md={6} gap="8px">
                      <Box className={classes.flexBetween}>
                        <Typography
                          sx={{ color: "#606479", textDecoration: "underline" }}
                        >
                          Category Name
                        </Typography>
                      </Box>
                      {shotInfoToDisplay.slice(4).map((shotKey) => {
                        return (
                          <div className={classes.categoryContainer}>
                            <div className={classes.initialContainer}>
                              <Typography className={classes.textFirstWord}>
                                {shotKey.charAt(0).toUpperCase()}
                                {shotKey.slice(1)}
                              </Typography>

                              <Typography className={classes.secondWord}>
                                :
                              </Typography>
                            </div>
                            <Typography
                              alignSelf="flex-end"
                              className={classes.lastWord}
                            >
                              {singleShot.shotMeta.hasOwnProperty(shotKey)
                                ? singleShot.shotMeta[shotKey] === ""
                                  ? "None"
                                  : singleShot.shotMeta[shotKey]
                                : "None"}
                            </Typography>
                          </div>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          </Box>
          <Box>
            {isEditing ? (
              <Box className={classes.flexEnd} sx={{ gap: "8px" }}>
                <Button
                  sx={{
                    minWidth: "0px",
                    color: "white",
                    backgroundColor: "#252A38",
                  }}
                  onClick={() => setIsEditing(false)}
                  className={classes.closeEdit}
                >
                  <ResponsivePhosphorIcon
                    sizes={{ md: 12, lg: 16, xl: 20 }}
                    icon="X"
                    fill="white"
                  />
                </Button>
                <Button
                  sx={{ minWidth: "0px", color: "white" }}
                  onClick={() => handleUpdateShot()}
                  variant="contained"
                  className={classes.closeEdit}
                >
                  <ResponsivePhosphorIcon
                    sizes={{ md: 12, lg: 16, xl: 20 }}
                    icon="Check"
                    fill="white"
                  />
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  sx={{ minWidth: "0px", color: "white" }}
                  onClick={() => setIsEditing(true)}
                >
                  <ResponsivePhosphorIcon
                    sizes={{ md: 12, lg: 16, xl: 20 }}
                    icon="PencilLine"
                    fill="white"
                  />
                </Button>
                <Button
                  sx={{ minWidth: "0px", color: "white" }}
                  onClick={() => setDeleteSingleShotModal(true)}
                >
                  <ResponsivePhosphorIcon
                    sizes={{ md: 12, lg: 16, xl: 20 }}
                    icon="Trash"
                    fill="white"
                  />
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <div
        style={{ height: "15px", position: "relative", borderRadius: "10px" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered ? (
          <div className={classes.addShotButtonContainer}>
            <Button
              variant="contained"
              className={classes.addNewShotButton}
              onClick={() =>
                handleOpenNewShotModal(
                  singleShot.shotNumber ? singleShot.shotNumber : 0
                )
              }
            >
              <ResponsivePhosphorIcon
                sizes={{ md: 12, lg: 14, xl: 16 }}
                icon="Plus"
                fill="white"
              />
            </Button>
          </div>
        ) : (
          <div style={{ height: "100%" }}></div>
        )}
      </div>
      <Modal
        open={deleteSingleShotModal}
        onClose={() => setDeleteSingleShotModal(false)}
        className={classes.flexCenter}
      >
        <DeleteModal
          handleDelete={handleDeleteSingleShot}
          name={singleShot.shotNumberText}
          type="Shot"
          handleCloseDeleteModal={() => setDeleteSingleShotModal(false)}
          isDeleting={isShotDeleting}
        />
      </Modal>
    </div>
  );
};
