import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  menuList: {
    backgroundColor: "#252A38",
    borderRadius: "20px",
    color: "white",
    border: "1px solid #606479",
    padding: "4px 10px !important",
    marginRight: "25px !important",
  },

  editMenu: {
    borderBottom: "1px solid #606479 !important",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    paddingBottom: "6px !important",
  },
  logOutMenu: {
    color: "red !important",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  userThumbnail: {
    height: "30px",
    width: "30px",
    borderRadius: "5px",
  },
  //Common styles
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
