import { makeStyles } from "@material-ui/core/styles";
import { themeType } from "../../../types/theme-types";

const useStyles = makeStyles((theme: themeType) => ({
  root: {
    height: "100%",
    backgroundColor: "#1B1D28",
  },
  breakDownText: {
    height: "6%",
    // borderBottom: "2px solid black",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 10px",
  },

  leftMenuAndRightDetails: {
    height: "92%",
    backgroundColor: "#252A38",
    padding: "10px 0px 10px 10px",
  },

  leftSideContents: {
    height: "100%",
    backgroundColor: "#1B1D28",
    borderRadius: "10px",
    padding: "5px",
  },
  breakDownElement: {
    height: "5%",
    textAlign: "center",
    color: "#6C65D9",
  },
  rightSideContainer: {
    height: "100%",
    borderRadius: "10px",
  },
  rightSideTitle: {
    height: "5%",
    backgroundColor: "#1B1D28",
    borderRadius: "10px",
    marginBottom: "10px",
    border: "2px solid #6C65D9",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 2px 4px 8px",
  },
  dotAndText: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  generateBreakdownButton: {
    color: "white !important",
    minWidth: "0px !important",
    borderRadius: "5px !important",
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  rightSideTools: {
    height: "4%",
    marginBottom: "5px",
    display: "flex",
    alignItems: "center",
    padding: "0px 5px",
    justifyContent: "space-between",
  },

  shortListContainer: {
    overflowY: "auto",
    paddingRight: "8px",
  },

  shortListCard: {
    backgroundColor: "#1B1D28",
    // marginBottom: "20px",
    padding: "10px",
    borderRadius: "10px",
  },

  shortListMenuItem: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    color: "#606479",
  },
  shortListItemHide: {
    display: "none",
  },

  bottomButtonContainer: {
    height: "6%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
    gap: "20px",
  },
  breakDownButton: {
    borderRadius: "5px",
  },

  storyBoardButton: {
    borderRadius: "5px",
  },

  singleCharacterOrLocationCard: {
    backgroundColor: "#1B1D28",
    marginBottom: "10px",
    padding: "10px",
    borderRadius: "10px",
  },

  searchBoxContainer: {
    height: "35px",
    width: "98%",
    backgroundColor: "#252A38",
    borderRadius: "10px",
    marginBottom: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "4px",
  },
  searchInput: {
    height: "80%",
    backgroundColor: "transparent",
    border: "none",
    width: "80%",
    outline: "none",
    padding: "4px 8px",
    color: "white",
    fontFamily: "Poppins !important",
  },

  modal: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "center",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  flexEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "relative",
  },

  //shot details styles

  addShotButtonContainer: {
    height: "100%",
    overflow: "hidden",
    backgroundColor: "#606479",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  addNewShotButton: {
    width: "40px !important",
    borderRadius: "10px !important",
    zIndex: 2,
    position: "absolute",
    padding: "5px 0px !important",
    color: "white !important",
    minWidth: "0px !important",
  },
  closeEdit: {
    cursor: "pointer",
    backgroundColor: "#252A38",
    padding: "2px",
    borderRadius: "5px",
  },
  confirmEdit: {
    cursor: "pointer",
    backgroundColor: "#6C65D9",
    padding: "2px",
    borderRadius: "5px",
  },
  shotInfo: {
    width: "550px",
    padding: "10px",
    borderRadius: "5px",
    border: "0.5px solid #6C65D9",
    backgroundColor: "#252A38",
    position: "absolute",
    right: "55%", // Adjust as needed//
    top: "-50%",
    zIndex: 99,
  },
  firstShotInfo: {
    width: "550px",
    padding: "10px",
    borderRadius: "5px",
    border: "0.5px solid #6C65D9",
    backgroundColor: "#252A38",
    position: "absolute",
    right: "55%", // Adjust as needed//
    top: "-5%",
    zIndex: 99,
  },
  dividerContainer: {
    display: "flex",
    gap: "20px",
  },
  categoryContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  initialContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    width: "40%",
  },
  initialContainer2: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    width: "50%",
  },
  textFirstWord: {
    color: "#F9F9F9",
    wordWrap: "break-word",
    width: "90%",
  },
  secondWord: {
    textAlign: "center",
    width: "10%",
  },
  lastWord: {
    wordWrap: "break-word",
    color: "#6C65D9",
    overflowWrap: "break-word",
  },
}));

export default useStyles;
