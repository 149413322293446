import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ScriptBreakdownContext } from "../../../Context/ContextDataProvider";
import {
  createNewProject,
  deleteProject,
  getAllProject,
  updateProject,
} from "../../../Services/projectPageServices/projectPageServices";
import { ProjectsType, SingleProjectType } from "../../../types/public-types";
import DeleteModal from "../../ReusableComponents/DeleteModal/DeleteModal";
import HomePageSkeleton from "../../ReusableComponents/SkeletonLoaders/HomePageSkeleton";
import CreateAndEditProjectModal from "../ProjectPageModals/CreateAndEditProjectModal";
import ProjectPageTools from "../ProjectPageTools/ProjectPageTools";
import useStyles from "./ProjectPageOverview.styles";
import SingleProjectCard from "./SingleProjectCard";

type NewOrEditModalDataType = {
  isModalOpen: boolean;
  type: string;
  projectDetails?: SingleProjectType | null;
};
type DeleteModalDataType = {
  isModalOpen: boolean;
  projectDetails: SingleProjectType | null;
};

const ProjectsPageOverview = () => {
  const classes = useStyles();
  const { getCredits } = useContext(ScriptBreakdownContext);
  const [projects, setProjects] = useState<ProjectsType>([]);
  const [sortByProjects, setSortByProjects] = useState<string>("");
  const [isProjectsLoaded, setIsProjectsLoaded] = useState<boolean>(false);
  const [isProjectAddingOrEditing, setIsProjectAddingOrEditing] =
    useState<boolean>(false);
  const [isProjectDeleting, setIsProjectDeleting] = useState<boolean>(false);

  //create or edit project modal state
  const [newOrEditProjectModalData, setNewOrEditProjectModalData] =
    useState<NewOrEditModalDataType>({
      isModalOpen: false,
      type: "",
      projectDetails: null,
    });
  //delete modal state
  const [deleteProjectData, setDeleteProjectData] =
    useState<DeleteModalDataType>({
      isModalOpen: false,
      projectDetails: null,
    });

  // open&close delete modal
  const handleOpenDeleteModal = (details: SingleProjectType) => {
    setDeleteProjectData({
      isModalOpen: true,
      projectDetails: details,
    });
  };

  const handleCloseDeleteModal = () => {
    setDeleteProjectData({
      isModalOpen: false,
      projectDetails: null,
    });
  };

  // open&close create or edit project modal
  const handleOpenNewOrEditModal = (
    type: string,
    details?: SingleProjectType
  ) => {
    setNewOrEditProjectModalData({
      isModalOpen: true,
      type: type,
      projectDetails: details ? details : null,
    });
  };

  const handleCloseNewOrEditModal = () => {
    setNewOrEditProjectModalData({
      isModalOpen: false,
      type: "",
      projectDetails: null,
    });
  };
  const [refreshCounter, setRefreshCounter] = useState<number>(0);

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  useEffect(() => {
    getProjects();
  }, [refreshCounter]);

  const getProjects = async () => {
    try {
      const res = await getAllProject();
      setProjects(res.data.projects);
      setIsProjectsLoaded(true);
    } catch (error) {
      toast.error("error get projects");
      setIsProjectsLoaded(true);
    }
  };

  const handleCreateNewProject = async (data: { name: string }) => {
    setIsProjectAddingOrEditing(true);
    toast.loading("Creating New Project");
    try {
      const res = await createNewProject(data);
      handleCloseNewOrEditModal();
      toast.success("Successfully Created New Project");
      updateRefreshCounter();
      setIsProjectAddingOrEditing(false);
    } catch (error) {
      toast.error("Error on creating project");
      setIsProjectAddingOrEditing(false);
    }
  };

  const handleUpdateProject = async (data: { name: string }) => {
    setIsProjectAddingOrEditing(true);
    toast.loading("Updating Project");
    try {
      const res = await updateProject(
        newOrEditProjectModalData?.projectDetails?.id,
        data
      );
      handleCloseNewOrEditModal();
      toast.success("Successfully Edited Project");
      setIsProjectAddingOrEditing(false);
      updateRefreshCounter();
    } catch (error) {
      toast.error("Error on updating project");
      setIsProjectAddingOrEditing(false);
    }
  };

  const handleDeleteProject = async () => {
    setIsProjectDeleting(true);
    toast.loading("Deleting Project");
    try {
      await deleteProject(deleteProjectData?.projectDetails?.id);
      toast.success("Successfully deleted project");
      updateRefreshCounter();
      handleCloseDeleteModal();
      setIsProjectDeleting(false);
    } catch (error) {
      toast.error("Error on delete project");
      setIsProjectDeleting(false);
    }
  };

  //Function to sort Assets
  function handleSubmitSort(sortBy: any) {
    switch (sortBy) {
      case "A-Z":
        projects?.sort((a, b) => a.name.localeCompare(b.name));
        setSortByProjects("A-Z");
        break;
      case "Z-A":
        projects?.sort((a, b) => b.name.localeCompare(a.name));
        setSortByProjects("Z-A");
        break;
      case "LatestModified":
        projects?.sort((a, b): any => {
          const dateA = new Date(a.updated_at);
          const dateB = new Date(b.updated_at);
          return dateB.getTime() - dateA.getTime();
        });
        setSortByProjects("LatestModified");
        break;
      case "LastModified":
        projects?.sort(function (a, b) {
          const dateA = new Date(a.updated_at);
          const dateB = new Date(b.updated_at);
          return dateA.getTime() - dateB.getTime();
        });
        setSortByProjects("LastModified");
        break;
      default:
        return;
    }
  }

  useEffect(() => {
    getCredits();
  }, []);

  return (
    <Box sx={{ mx: 5, mt: 3 }}>
      <ProjectPageTools
        handleOpenNewOrEditModal={handleOpenNewOrEditModal}
        handleSubmitSort={handleSubmitSort}
        sortByProjects={sortByProjects}
      />
      <Box className={classes.projectsContainer}>
        {isProjectsLoaded ? (
          <Grid container spacing={2}>
            {projects.map((single: SingleProjectType) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={2}
                  key={single.id}
                  sx={{ width: "100%" }}
                >
                  <SingleProjectCard
                    projectDetails={single}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                    handleOpenNewOrEditModal={handleOpenNewOrEditModal}
                  />
                </Grid>
              );
            })}
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              sx={{ minHeight: "200px" }}
            >
              <Button
                className={classes.addNewProjectButton}
                onClick={() => handleOpenNewOrEditModal("new")}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  color="#606479"
                >
                  + Add New Project
                </Typography>
              </Button>
            </Grid>
          </Grid>
        ) : (
          <HomePageSkeleton />
        )}
      </Box>
      <Box className={classes.footer}>
        <Typography className={classes.footerText}>
          Projects Added:{" "}
          <span className={classes.footerTextTwo}>{projects.length}</span>
        </Typography>
        {/* <Question size={24} color="#606479" /> */}
      </Box>
      <Modal
        open={newOrEditProjectModalData.isModalOpen}
        onClose={() => handleCloseNewOrEditModal()}
        className={classes.flexCenter}
      >
        <CreateAndEditProjectModal
          type={newOrEditProjectModalData?.type}
          handleClose={handleCloseNewOrEditModal}
          handleClick={
            newOrEditProjectModalData?.type === "new"
              ? handleCreateNewProject
              : handleUpdateProject
          }
          selectedProject={newOrEditProjectModalData.projectDetails}
          addingOrEditing={isProjectAddingOrEditing}
        />
      </Modal>
      <div>
        <Modal
          open={deleteProjectData?.isModalOpen}
          onClose={() => handleCloseDeleteModal()}
          className={classes.flexCenter}
        >
          <DeleteModal
            handleDelete={handleDeleteProject}
            name={deleteProjectData?.projectDetails?.name}
            type="Project"
            handleCloseDeleteModal={handleCloseDeleteModal}
            isDeleting={isProjectDeleting}
          />
        </Modal>
      </div>
    </Box>
  );
};

export default ProjectsPageOverview;
