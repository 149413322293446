import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 960,
      lg: 1280,
      xl: 1537,
    },
  },
  palette: {
    primary: {
      main: "#6C65D9", //purple
      light: "#F9F9F9", //white
      dark: "#1B1D28", //dark blue
    },
    secondary: {
      main: "#252A38", //light blue
      light: "#606479", //grey blue
      dark: "#000000", //black
    },
    info: {
      main: "#6C65D9", //purple
    },
    text: {
      primary: "#F9F9F9",
      secondary: "#606479",
      disabled: "#606479",
    },
    warning: {
      main: "#FF0000", //red
    },
    error: {
      main: "#FF0000", //red
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          minWidth: "0px",
        },
        contained: {
          backgroundColor: "#6C65D9",
          border: "1px solid transparent",
          padding: "4px",
          "&:hover": {
            backgroundColor: "#1B1D28",
            border: "1px solid #6C65D9",
          },
          "&.Mui-disabled": {
            backgroundColor: "#606479",
            color: "#1B1D28",
          },
        },
        outlined: {
          backgroundColor: "#252A38",
          border: "1px solid #6C65D9",
          color: "white",
          padding: "4px 8px",
          "&:hover": {
            backgroundColor: "#1B1D28",
            border: "1px solid #6C65D9",
          },
          "&.Mui-disabled": {
            border: "1px solid #606479",
            backgroundColor: "#252A38",
            color: "#606479",
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          paddingLeft: "6px",
        },
      },
    },
  },
});

export default theme;
