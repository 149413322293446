import { createContext, useEffect, useState } from "react";
import { GetUserProfileApi } from "../Services/authServices/authServices";
import { getAvailableCredits } from "../Services/creditPageServices/creditPageServices";
import { UserDetailsType } from "../types/public-types";

export const ScriptBreakdownContext = createContext<any>(null);

const ScriptBreakdownDataProvider = ({ children }: { children: any }) => {
  const allData = ScriptBreakdownProviderHook();
  return (
    <ScriptBreakdownContext.Provider value={allData}>
      {children}
    </ScriptBreakdownContext.Provider>
  );
};

export default ScriptBreakdownDataProvider;

const ScriptBreakdownProviderHook = () => {
  const [availableCredits, setAvailableCredits] = useState<number>(0);
  const [freeCredits, setFreeCredits] = useState<number>(0);
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<UserDetailsType>();

  const getCredits = async () => {
    try {
      const result = await getAvailableCredits();
      setAvailableCredits(result.data.availableCredits);
      setFreeCredits(result.data.freeCredits);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getUserData = async () => {
    try {
      const result = await GetUserProfileApi();
      setLoggedInUserDetails(result.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCredits();
    getUserData();
  }, []);
  return {
    getCredits,
    availableCredits,
    loggedInUserDetails,
    getUserData,
    freeCredits,
  };
};
