import { Box, Grid, Typography } from "@mui/material";
import {
  SequenceType,
  ShotListType,
  SingleShotType,
} from "../../types/public-types";
import useStyles from "./ExportPreview.style";
import { getShotListApi } from "../../Services/breakdownServices/breakdownServices";
import toast from "react-hot-toast";
import { useCallback, useEffect, useMemo, useState } from "react";
import Loader from "../ReusableComponents/Loader/Loader";
import { NoStoryBoardImage } from "../ReusableComponents/SvgAndIcons/SvgIcons";
import React from "react";

interface Props {
  selectedForPreview?: SequenceType;
}

const ShotsListOfPreview = ({ selectedForPreview }: Props) => {
  const classes = useStyles();

  const [isShotsLoaded, setIsShotsLoaded] = useState(false);
  const [shotList, setShotList] = useState<ShotListType>([]);

  const handleGetShotList = async () => {
    setIsShotsLoaded(false);
    try {
      const res = await getShotListApi(
        selectedForPreview?.selectedBreakdown
          ? selectedForPreview?.selectedBreakdown
          : ""
      );
      setShotList(res.data.shots);
      setIsShotsLoaded(true);
    } catch (error) {
      toast.error("Error getting shots");
      setIsShotsLoaded(true);
    }
  };

  useEffect(() => {
    if (selectedForPreview?.selectedBreakdown) {
      handleGetShotList();
    } else {
      setShotList([]);
      setIsShotsLoaded(true);
    }
  }, [selectedForPreview?.selectedBreakdown]);

  const getSelectedStoryboard = (singleShot: any) => {
    const storyboardPath = singleShot?.storyboardPaths.find(
      (storyboardPath: any) =>
        storyboardPath.version === singleShot.selectedStoryboardVersion
    );
    return storyboardPath?.storyboardImageURL || "";
  };

  return (
    <Box className={classes.shotListRoot}>
      <Box className={classes.selectedSequence}>
        <Typography>{selectedForPreview?.sceneTitle}</Typography>
        <Typography>
          <span style={{ color: "#6C65D9" }}>Shot - </span>
          {shotList?.length}
        </Typography>
      </Box>
      {isShotsLoaded ? (
        <>
          {shotList?.length ? (
            <>
              {shotList.map((singleShot: any, index) => (
                <Box key={index} className={classes.singleShot}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item md={3}>
                      <Box className={classes.shotNameImg}>
                        {singleShot.selectedStoryboardVersion ? (
                          <img
                            src={getSelectedStoryboard(singleShot)}
                            alt="shot image"
                            className={classes.shotImg}
                          />
                        ) : (
                          <Box className={classes.noImgBox}>
                            <NoStoryBoardImage />
                          </Box>
                        )}
                        <Typography>{singleShot.shotNumberText}</Typography>
                      </Box>
                    </Grid>
                    <Grid item md={7}>
                      <Typography sx={{ fontSize: "12px", color: "#92949C" }}>
                        {singleShot.description}
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography
                        sx={{ textAlign: "right", color: "#606479 " }}
                      >
                        Sequence : 03
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </>
          ) : (
            "No Shots Found"
          )}
        </>
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default ShotsListOfPreview;
