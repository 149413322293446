import axios from "axios";
import { ROUTES } from "../Routes";

export const readCookie = (name: string) => {
  let key = name + "=";
  let cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(key) === 0) {
      return cookie.substring(key.length, cookie.length);
    }
  }
  return false;
};

//!URL constants
//Needs to be modifed
const SSO_URL_FRONTEND = process.env.REACT_APP_BASE_URL_SSOF;
const DOMAIN = process.env.REACT_APP_BASE_URL_DOMAIN;
const APP_URL = process.env.REACT_APP_BASE_URL_MCF?.split("/")[2];

export const getPayload = (token: any) => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

//!funciton to delete a cookie
export function deleteCookie(name: string) {
  var now = new Date();
  now.setMonth(now.getMonth() - 10);
  let expiration = now.toUTCString();
  createCookie(name, "", expiration);
}

export function createCookie(key: string, value: string, time?: string) {
  console.log("creating cookie");
  let expiration;
  if (!time) {
    var now = new Date();
    // now.setMinutes(now.getMinutes() + 5);
    key.includes("access")
      ? now.setMinutes(now.getMinutes() + 5)
      : now.setHours(now.getHours() + 168);
    expiration = now.toUTCString();
  } else {
    //console.log("time was passed so setting expiraiton time");
    expiration = time;
  }

  //in Production Keep It back To DOMAIN
  const domain = DOMAIN;
  let cookie =
    escape(key) +
    "=" +
    escape(value) +
    ";expires=" +
    expiration +
    ";" +
    "path=/;" +
    "domain=" +
    domain;    
  document.cookie = cookie;
}

axios.interceptors.request.use(
  (config) => {
    let accessToken = readCookie("access");
    // console.log("call request");
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    let refreshToken = readCookie("refresh");
    if (
      refreshToken &&
      (error.response.status === 401 || 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      return axios
        .post(`${process.env.REACT_APP_BASE_URL}user/refresh/`, {
          refreshToken: refreshToken,
        })
        .then((res) => {
          if (res.status === 200 && res?.data?.access_token) {
            createCookie("access", res.data.access_token);
            console.log("Access token refreshed!");
            return axios(originalRequest);
          } else {
            deleteCookie("access");
            deleteCookie("refresh");
            window.location.href = ROUTES.LOG_IN;
          }
        });
    } 
    return Promise.reject(error);
  }
);
