import { Box, Button, Grid, Typography } from "@mui/material";
import { Slideshow, SortAscending } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { getAllCharactersApi } from "../../../Services/characterAndLocationServices/characterAndLocationServices";
import { Character, ProjectIdType } from "../../../types/public-types";
import Loader from "../../ReusableComponents/Loader/Loader";
import { WhiteDot } from "../../ReusableComponents/SvgAndIcons/SvgIcons";
import useStyles from "./Breakdown.style";

interface Props {
  projectId: ProjectIdType;
}
const CharactersViewer = ({ projectId }: Props) => {
  const classes = useStyles();
  const [isCharactersLoaded, setIsCharactersLoaded] = useState(false);
  const [characters, setCharacters] = useState<Character[]>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    getAllCharacters();
  }, [projectId]);

  const getAllCharacters = async () => {
    try {
      const res = await getAllCharactersApi(projectId);
      setCharacters(res.data.characters);
      setTotal(res.data.size);
      setIsCharactersLoaded(true);
    } catch (error) {
      toast.error("Error on getting characters");
    }
  };

  return (
    <div>
      <Box className={classes.rightSideTitle}>
        <Typography className={classes.dotAndText} sx={{ py: 1 }}>
          <WhiteDot />
          <span>Characters</span>
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>Total-{total}</Typography>
      </Box>
      <Box className={classes.rightSideTools}>
        <Button
          sx={{
            display: "flex",
            color: "#606479",
            gap: "5px",
            alignItems: "center",
            minWidth: "0px",
          }}
        >
          <Slideshow size={22} />
          <span>Card View</span>
        </Button>
        <Button
          sx={{
            display: "flex",
            color: "#606479",
            gap: "5px",
            alignItems: "center",
            minWidth: "0px",
          }}
        >
          <SortAscending size={22} />
          <span>Sort</span>
        </Button>
      </Box>
      <Box className={classes.shortListContainer}>
        {isCharactersLoaded ? (
          <>
            {characters.length ? (
              <>
                {characters.map((singleCharacter: Character) => (
                  <Grid
                    container
                    className={classes.singleCharacterOrLocationCard}
                  >
                    <Grid item md={4}>
                      <Typography sx={{ textAlign: "start" }}>
                        {singleCharacter.name}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography sx={{ textAlign: "center" }}>
                        <span style={{ color: "#8D8E94" }}>Sequences : </span>
                        {singleCharacter.linkedSequence.length}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography sx={{ textAlign: "end" }}>
                        <span style={{ color: "#8D8E94" }}>Shots : </span>
                        {singleCharacter.linkedShots.length}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </>
            ) : (
              <p>No Characters found</p>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Box>
    </div>
  );
};

export default CharactersViewer;
