import axios from "axios";
import {
  BreakdownVersionType,
  ProjectIdType,
  SequenceListType,
  ShotListType,
} from "../../types/public-types";

const baseUrl = process.env.REACT_APP_BASE_URL;

interface SequenceListApiResponse {
  size: number;
  sequences: SequenceListType;
}
interface BreakdownVersionListApiResponse {
  size: number;
  breakdowns: BreakdownVersionType;
}
interface ShotListListApiResponse {
  size: number;
  shots: ShotListType;
}

export const getSequenceListApi = async (
  projectId: ProjectIdType,
  fileId: string
) => {
  const res = await axios.post<SequenceListApiResponse>(
    `${baseUrl + `sequence/list-sequence/`}`,
    {
      projectId: projectId,
      fileId: fileId,
    }
  );
  return res;
};

export const getBreakdownVersionListApi = async (
  projectId: ProjectIdType,
  sequenceId: string | undefined
) => {
  const res = await axios.post<BreakdownVersionListApiResponse>(
    `${baseUrl + `breakdown/list-breakdown/`}`,
    {
      projectId: projectId,
      sequenceId: sequenceId,
    }
  );
  return res;
};

export const getShotListApi = async (breakdownId: string) => {
  const res = await axios.get<ShotListListApiResponse>(
    `${baseUrl + `shot/${breakdownId}`}`
  );
  return res;
};

interface UpdateShotPayload {
  breakdownId?: string;
  shotId?: string;
  description?: string;
  cameraPosition?: string;
  characters?: string;
  location?: string;
}

export const updateShotApi = async ({
  breakdownId,
  shotId,
  description,
  cameraPosition,
  characters,
  location,
}: UpdateShotPayload) => {
  const res = await axios.patch(
    `${baseUrl + `shot/${breakdownId}/${shotId}`}`,
    {
      description: description,
      cameraPosition: cameraPosition,
      characters: characters,
      location: location,
    }
  );
  return res;
};
export const deleteShotApi = async ({
  breakdownId,
  shotId,
}: {
  breakdownId: string;
  shotId: string;
}) => {
  const res = await axios.delete(
    `${baseUrl + `shot/${breakdownId}/${shotId}`}`
  );
  return res;
};

export const generateNewBreakdownVersion = async (
  projectId: ProjectIdType,
  sequenceId: string | undefined,
  fileId: string,
  breakdownLevel: number
) => {
  const res = await axios.post(`${baseUrl + `breakdown/generate-breakdown/`}`, {
    projectId: projectId,
    sequenceId: sequenceId,
    fileId: fileId,
    breakdownLevel: breakdownLevel,
  });
  return res;
};

export const selectBreakdownVersionApi = async (
  fileId: string,
  sequenceId: string | undefined,
  selectedBreakdownId: string
) => {
  const res = await axios.patch(`${baseUrl + `sequence/set-breakdown/`}`, {
    fileId: fileId,
    sequenceId: sequenceId,
    selectedBreakdownId: selectedBreakdownId,
  });
  return res;
};

export const deleteBreakdownVersionApi = async (id: string) => {
  const res = await axios.delete(`${baseUrl + `breakdown/${id}`}`);
  return res;
};

interface createApiParams {
  cameraPosition: string;
  description: string;
  dialogue: string;
  location: string;
  characters: string;
  projectId?: string;
  sequenceId?: string;
  breakdownId?: string;
  shotNumber: number;
  shotNumberText: string;
}

export const createNewShotApi = async ({
  cameraPosition,
  description,
  dialogue,
  location,
  characters,
  projectId,
  sequenceId,
  breakdownId,
  shotNumber,
  shotNumberText,
}: createApiParams) => {
  const res = await axios.post(`${baseUrl + `shot/`}`, {
    cameraPosition,
    description,
    dialogue,
    location,
    characters,
    projectId,
    sequenceId,
    breakdownId,
    shotNumber,
    shotNumberText,
  });
  return res;
};
